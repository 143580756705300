<template>
    <div>
        <div class="bg-footer pt-4">
            <div class="row px-3">
                <!-- <div class="col-md-3 text-md-center">
                    <router-link to="/" class="navbar-brand pl-md-0"><img width="75%" src="@/assets/images/sm_logo_def.svg" alt=""></router-link>
                </div> -->
                <div class="col-md-4 ps-md-0">
                    <p class="title-footer title-footer-margin">{{ $t("message.serenaManuelSpa") }}</p>
                    <div class="mt-3 div-footer title-footer-margin">
                        <p v-html="$t('message.addressSerenaManuelFooter')"></p>
                        <p>Tel +39 0522 977457<br/>Fax +39 0522 977107</p>
                        <p>{{ $t("message.vat") }} IT00131460354</p>
                        <p><a href="mailto:info@serenamanuel.com">info@serenamanuel.com</a></p>
                    </div>
                </div>
                <div class="col-md-6 text-md-center">
                    <div class="align-in-box">
                        <p class="title-footer title-footer-margin">{{ $t("message.download") }}</p>
                        <div class="mt-3 div-footer">
                            <ul>
                                <li @click="utils.download(element.files[selectedLanguage.locale].file)" v-for="element in info" :key="element.id" class="mb-2 li-footer cursor-pointer" v-show="!utils.checkObjectIsUndefinedOrEmpty(element.files[selectedLanguage.locale])">
                                    <img src="@/assets/icons/freccia_footer.svg" class="image-icon" alt="">
                                    {{ element.title[selectedLanguage.locale] }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 text-md-end">
                    <div class="align-in-box">
                        <p class="title-footer title-footer-margin">{{ $t("message.customerArea") }}</p>
                        <div class="mt-3 div-footer">
                            <ul>
                                <li v-show="element.logged === true ? true : !isLogged" v-for="element in customers" :key="element.title" @click="$router.push({name: element.name, params: {locale: selectedLanguage.locale}})" class="mb-2 cursor-pointer li-footer">
                                    <img src="@/assets/icons/freccia_footer.svg" class="image-icon" alt="">
                                    {{ $t(element.title) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-main text-center py-3 footer-rights">
            &#169; Serena Manuel S.p.A. - All rights reserved
        </div>
    </div>
</template>

<script>
import DownloadService from '../../api/DownloadService'
import loader from '../../config/loader'
import { mapGetters } from 'vuex'
import utils from '../../utils/utils'
import constants from '../../config/constants'
export default {
    data() {
        return {
            utils,
            info: [],
            customers: [
                {
                    title: "message.personalArea",
                    name: "CustomerAreaLogin",
                    logged: true
                },
                {
                    title: "message.signUp",
                    name: "CustomerAreaLogin",
                    logged: false
                },
            ]
        }
    },
    async mounted() {
        loader.showLoader()
        var string = utils.getStringFilter(constants.COLUMNS_FOOTER, true)
        await DownloadService.fetchDownloadsFooter(string)
            .then(resp => {
                this.info = resp.data.data
            })
            .catch(err => {
                console.log(err)
            })
        loader.hideLoader()
    },
    computed: {
        ...mapGetters([
            "selectedLanguage",
            "isLogged",
		]),
    }
}
</script>

<style lang="scss" scoped>

// @media all and (min-width: 768px) {
    .title-footer-margin {
        margin-left: -10px;
    }
    li.li-footer {
	margin-left: -10px;

        img {
            margin-right: 10px;
        }
    }
// }
</style>