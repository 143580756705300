<template>
  <div class="pt-lg-2">
    <p class="color-dark mb-0 mb-lg-4 font-28">{{ $t("message.filters") }}</p>
    <!-- BEGIN filter -->
    <div class=" mt-3">
      <div v-for="(filter, indexFilter) in dataFilters" :key="filter.title" id="accordion"
           class="accordion rounded overflow-hidden">
        <b-card class="border-0 rounded-0" no-body>
          <b-card-header header-tag="header"
                         class="card-header position-relative pointer-cursor px-3 py-10px d-flex align-items-center"
                         v-b-toggle="filter.title" :class="{'card-header__selected': isSelected(filter)}">
            {{ $t(`message.${filter.title}`) }}
            <span class="when-opened position-absolute top-50 end-0 translate-middle">
                            <i class="fa fa-chevron-up"/>
                        </span>
            <span class="when-closed position-absolute top-50 end-0 translate-middle">
                            <i class="fa fa-chevron-down"/>
                        </span>
          </b-card-header>
          <b-collapse :id="filter.title" :visible="filter.id === 'categories' || isSelected(filter)">
            <b-card-body v-if="filter.type === 'list'">
              <div class="form-check mb-2" v-for="(element) in filter.list" :key="element.text">
                <input class="form-check-input border-radius-0" :id="element.id" type="checkbox"
                       v-model="element.selected">
                <label class="form-check-label" :for="element.id">
                  {{ element.title[selectedLanguage.locale] }}
                </label>
              </div>
            </b-card-body>
            <b-card-body v-else-if="filter.type === 'search'">
              <b-input-group class="input-group input-group-lg mb-3">
                <input @keyup.enter="filtersFn(true, true)" type="text"
                       :placeholder="`${ $t('message.searchBy') } ''${$t(`message.${filter.title}`)}''`"
                       class="form-control input-white" v-model="filter.value"/>
                <button type="button" class="btn btn-outline-primary btn-primary-no-hover"
                        @click="filtersFn(true, true)">
                  <img src="@/assets/icons/cerca_azzurro.svg" class="image-icon-20" alt="">
                </button>
              </b-input-group>
            </b-card-body>
            <b-card-body class="p-0" v-else-if="filter.type === 'range'">
              <div class="row m-0 w-100">
                <label class="col-md-1 fw-normal vertically-center">0</label>
                <div class="col-md-5 vertically-center">
                  <vue-slider v-if="selectedUnitMeasure === constants.MILLIMETERS" :max="max" v-model="filter.value"
                              :enable-cross="false" width="100%" :tooltip="'none'"></vue-slider>
                  <vue-slider v-else :adsorb="true" v-model="filter.value" :data="dataInches" :data-value="'value'"
                              :data-label="'name'" :enable-cross="false" width="100%" :tooltip="'none'"></vue-slider>
                  <!-- <input type="range" class="form-range" v-model="filter.value" id="customRange1" step="0.5" min="0" max="100"> -->
                </div>
                <label v-if="selectedUnitMeasure === constants.MILLIMETERS"
                       class="col-md-2 fw-normal vertically-center">100</label>
                <label v-else class="col-md-2 fw-normal vertically-center">{{ lastSizeInch }}</label>
                <div class="col-md-4">
                  <select class="form-select" v-model="selectedUnitMeasure">
                    <option v-for="unit in unitMeasure" :key="unit" :value="unit">{{ unit }}</option>
                  </select>
                </div>
                <div class="my-2">
                  <label>{{ $t("message.fromRange") }}</label>
                  <input type="text" class="form-control mb-3" :value="getName(filter.value[0])"
                         @blur="e => setValueRange(indexFilter, 0, e.target.value)">
                  <label>{{ $t("message.toRange") }}</label>
                  <input type="text" class="form-control mb-3" :value="getName(filter.value[1])"
                         @blur="e => setValueRange(indexFilter, 1, e.target.value)">
                  <strong>{{ `${getName(filter.value[0])} - ${getName(filter.value[1])}` }} {{
                      selectedUnitMeasure
                    }}</strong>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="row w-100 mt-3">
        <div class="col-6 ps-0">
          <b-button class="w-100" @click="filtersFn(true, true)" variant="primary">
            {{ $t("message.search") }} <img src="@/assets/icons/cerca_bianco.svg" class="image-icon-20 ms-md-2" alt="">
          </b-button>
        </div>
        <div class="col-6 pe-0">
          <b-button class="w-100 btn-reset" @click="reset" variant="danger">
            {{ $t("message.reset") }} <img src="@/assets/icons/reset.svg" class="image-icon-20 ms-md-2 pe-0" alt="">
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CatalogService from '../../api/CatalogService.js'
import constants from '../../config/constants.js'
import utils from '../../utils/utils.js'

export default {
  props: ["category"],
  data() {
    return {
      constants,
      utils,
      valueDiameter: 0,
      unitMeasure: [
        constants.MILLIMETERS,
        constants.INCHES
      ],
      max: 100,
      dataInches: constants.DATA_INCHES,
      selectedUnitMeasure: constants.MILLIMETERS,
      dataFilters: [{
        id: "categories",
        title: "category",
        type: "list",
        list: [],
        index: 2
      },
        {
          id: "materials",
          title: "material",
          type: "list",
          list: [],
          index: 4
        },
        {
          id: "title",
          title: "description",
          type: "search",
          value: "",
          index: 5
        },
        {
          id: "article",
          title: "article",
          type: "search",
          value: "",
          index: 6
        },
        {
          id: "code",
          title: "code",
          type: "search",
          value: "",
          index: 8
        },
        {
          id: "diameter",
          title: "diameter",
          type: "range",
          value: [0, 0],
          index: 5,
          unitMeasure: constants.MILLIMETERS
        }]
    }
  },
  watch: {
    selectedUnitMeasure() {
      var index = utils.findIndexWithAttr(this.dataFilters, "id", "diameter")
      this.dataFilters[index].value[0] = 0
      this.dataFilters[index].value[1] = 0
      this.dataFilters[index].unitMeasure = this.unitMeasure
      // if(this.selectedUnitMeasure === constants.MILLIMETERS) {
      //     this.dataFilters[index].value[1] = 100
      // } else {
      //     this.dataFilters[index].value[1] = "3"
      // }
    }
  },
  async mounted() {
    var stringCategories = utils.getStringFilter(constants.COLUMNS_CATEGORY, true)
    // await CatalogService.fetchCategories(stringCategories)
    //     .then(resp => {
    //         var index = utils.findIndexWithAttr(this.dataFilters, "id", "category")
    //         this.dataFilters[index].list = resp.data.data
    //     })
    await utils.getCategories()
        .then(resp => {
          var index = utils.findIndexWithAttr(this.dataFilters, "id", "categories")
          this.dataFilters[index].list = resp
        })
        .catch(err => console.log(err))
    if (this.isLogged) {
      await CatalogService.fetchCategoriesReserved(stringCategories)
          .then(resp => {
            var index = utils.findIndexWithAttr(this.dataFilters, "id", "categories")
            // console.log(this.dataFilters[index], resp.data.data)
            this.dataFilters[index].list = this.dataFilters[index].list.concat(resp.data.data)

          })
    }
    this.$forceUpdate()
    var stringMaterials = utils.getStringFilter(constants.COLUMNS_MATERIAL, true)
    await CatalogService.fetchMaterials(stringMaterials)
        .then(resp => {
          var index = utils.findIndexWithAttr(this.dataFilters, "id", "materials")
          this.dataFilters[index].list = resp.data.data
        })
    if (utils.checkIsUndefinedOrEmpty(this.category)) {
      if ((this.filters != null)) {
        Object.assign(this.dataFilters, this.filters)
        this.filtersFn()
        this.$forceUpdate()
      }
    } else {
      var indexCategory = utils.findIndexWithAttr(this.dataFilters, "id", "categories")
      var indexElement = utils.findIndexWithAttr(this.dataFilters[indexCategory].list, "id", this.category)
      this.$set(this.dataFilters[indexCategory].list[indexElement], "selected", true)
      this.filtersFn(false)
      this.$forceUpdate()
    }
  },
  methods: {
    isSelected(filter) {
      var toReturn = false
      switch (filter.type) {
        case "list":
          for (let value of filter.list) {
            if (value.selected) {
              toReturn = true
              break
            }
          }
          break;
        case "search":
          if (utils.checkIsUndefinedOrEmpty(filter.value)) {
            break
          }
          toReturn = true
          break;
        case "range":
          if (utils.checkIsUndefinedOrEmpty(filter.value[0])) {
            break
          }
          if (utils.checkIsUndefinedOrEmpty(filter.value[1])) {
            break
          }
          if (filter.value[1] == 0) {
            break
          }
          // if(filter.unitMeasure === constants.MILLIMETERS){
          toReturn = true
          break
          // }
          // var inch = filter.value[0].includes("/")
          // console.log(filter.value[0], filter.value[1])
          // break;
        default:
          console.log("default")
      }
      return toReturn
    },
    setValueRange(indexFilter, indexValue, value) {
      if (this.selectedUnitMeasure === constants.MILLIMETERS) {
        this.$set(this.dataFilters[indexFilter].value, indexValue, value)
        return
      }
      var index = utils.findIndexWithAttr(this.dataInches, "name", value)
      this.$set(this.dataFilters[indexFilter].value, indexValue, this.dataInches[index].value)
    },
    getName(value) {
      if (this.selectedUnitMeasure === constants.MILLIMETERS) {
        return value
      }
      var index = utils.findIndexWithAttr(this.dataInches, "value", value)
      return this.dataInches[index].name
    },
    filtersFn(isSave = true, isFilter = false) {
      if (isFilter) {
        this.$store.commit("setYProducts", 0)
      }
      if (isSave) {
        this.$store.commit("saveFilters", this.dataFilters)
      }
      this.$emit("filters", this.dataFilters, isFilter)
    },
    reset() {
      this.$store.commit("setYProducts", 0)
      this.valueDiameter = 0
      this.dataFilters.forEach(element => {
        switch (element.type) {
          case 'list':
            element.list.forEach(dataList => {
              dataList.selected = false
            });
            break
          case 'range':
            element.value = [0, 0]
            break
          default:
            element.value = ""
        }
      });
      this.$store.commit("resetFilters")
      this.filtersFn(false)
    }
  },
  computed: {
    ...mapGetters([
      "filters",
      "selectedLanguage",
      "isLogged"
    ]),
    lastSizeInch() {
      return constants.DATA_INCHES[constants.DATA_INCHES.length - 1].name
    }
  }
}
</script>