<template>
    <panel :title="$t('message.availabilityTravel', 'it')">
        <div class="row">
            <div class="form-group col-12 row">
                <h4 class="mb-3 ps-0">Italia</h4>
                <label class="col-sm-6 ps-0">Viaggi di lavoro (presso clienti o fornitori)</label>
                <div class="col-sm-6">
                    <select class="form-select" v-model="travel_italy">
                        <option v-for="(availability) in availabilities" :key="availability.id" :value="availability.id">
                            {{ availability.value }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group col-12 row mb-4 mt-0">
                <label class="col-sm-6 ps-0">Fiere e Manifestazioni</label>
                <div class="col-sm-6">
                    <select class="form-select" v-model="exhibitions_italy">
                        <option v-for="(availability) in availabilities" :key="availability.id" :value="availability.id">
                            {{ availability.value }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group col-12 row mt-0">
                <h4 class="mb-3 ps-0">Estero</h4>
                <label class="col-sm-6 ps-0">Viaggi di lavoro (presso clienti o fornitori)</label>
                <div class="col-sm-6">
                    <select class="form-select" v-model="travel_foreign">
                        <option v-for="(availability) in availabilities" :key="availability.id" :value="availability.id">
                            {{ availability.value }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group col-12 row mt-0">
                <label class="col-sm-6 ps-0">Fiere e Manifestazioni</label>
                <div class="col-sm-6">
                    <select class="form-select" v-model="exhibitions_foreign">
                        <option v-for="(availability) in availabilities" :key="availability.id" :value="availability.id">
                            {{ availability.value }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </panel>
</template>

<script>
// import utils from '../../utils/utils'

function initialState() {
    return {
        travel_italy: "",
        travel_foreign: "",
        exhibitions_italy: "",
        exhibitions_foreign: ""
    }
}

export default {
    data() {
        return initialState()
    },
    computed: {
        availabilities() {
            return [
                {
                    id: "",
                    value: ""
                },
                {
                    id: "n",
                    value: this.$t("message.nothing", 'it')
                },
                {
                    id: "o",
                    value: this.$t("message.oneDay", 'it')
                },
                {
                    id: "f",
                    value: this.$t("message.fiveDays", 'it')
                },
                {
                    id: "b",
                    value: this.$t("message.beyond", 'it')
                }
            ]
        }
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>