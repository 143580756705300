import constants from "../config/constants"
import CatalogService from '../api/CatalogService.js'

export default {
    checkIsUndefined,
    checkIsUndefinedOrEmpty,
    checkArrayIsUndefinedOrEmpty,
    checkObjectIsUndefinedOrEmpty,
    checkAreEquals,
    isMobile,
    levelLanguage,
    levelTechnicalDesign,
    levelInformatics,
    insertAt,
    findObject,
    findObjectDownload,
    findObjectLowercase,
    findIndexWithAttr,
    deleteElementArray,
    levelGeneralMetalworkers,
    newExperience,
    download,
    getStringFilter,
    baseURL,
    getCategories,
    dividerArray,
    getCurriculumData,
    sortByCode,
    stringLogOnlyArticle,
    stringLogArticleWithImage,
    stringLogArticleWithComponent,
    getPublicFile,
    downloadFile,
    decodeHtml
}

function downloadFile(data) {
    if (checkObjectIsUndefinedOrEmpty(data)) {
        return
    }
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var blob = new Blob([data.data], {type: data.headers['content-type']})
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    const filename = data.headers['content-disposition'].split('filename=')[1]
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function getPublicFile(url) {
    if (checkIsUndefinedOrEmpty(url)) {
        return ''
    }
    return url.split('?')[0]
}

function stringLogOnlyArticle(id) {
    return JSON.stringify({
        article_id: id
    })
}

function stringLogArticleWithImage(id, image) {
    return JSON.stringify({
        article_id: id,
        article_image_id: image
    })
}

function stringLogArticleWithComponent(id, component) {
    return JSON.stringify({
        article_id: id,
        component_id: component
    })
}

function sortByCode(array) {
    return array.sort((a, b) => {
        if (isNaN(a.code)) {
            return -1
        }
        if (isNaN(b.code)) {
            return 1
        }
        return (parseInt(a.code) > parseInt(b.code)) ? 1 : ((parseInt(b.code) > parseInt(a.code)) ? -1 : 0)
    })
}

function getCurriculumData(data) {
    if (checkObjectIsUndefinedOrEmpty(data)) {
        return []
    }
    var toReturn = []
    for (var key in data) {
        if (constants.KEYS_CURRICULUM.includes(key)) {
            toReturn.push({
                key: key,
                value: data[key]
            })
        }
    }
    return toReturn
}

function dividerArray(array) {
    return array.toString().replace(",", "|")
}

function baseURL() {
    return process.env.NODE_ENV === 'development' ? constants.DEVELOP_API_URL : constants.PRODUCTION_API_URL
}

function getStringFilter(columns, order = false) {
    var string = ""
    var arrayData = []
    columns.forEach(col => {
        arrayData.push(col.field)
    })
    arrayData.forEach((element, index) => {
        string += `&columns[${index}][data]=${element}&columns[${index}][name]=&columns[${index}][searchable]=true&columns[${index}][orderable]=true&columns[${index}][search][value]=&columns[${index}][search][regex]=true`
        if (element === 'weight' && order) {
            string += `&order[0][column]=${index}&order[0][dir]=asc`
        }
    });
    return string
}

function download(link) {
    window.open(link, "_blank")
}

function isMobile() {
    if (window.innerWidth < 769) {
        return true
    }
    return false
}

function levelLanguage() {
    return {
        comprehension_listen: "",
        comprehension_read: "",
        verbal_interaction: "",
        verbal_oral: "",
        write: ""
    }
}

function levelTechnicalDesign() {
    return {
        drawing_read: "",
        drawing_draw: "",
        drawing_cad: ""
    }
}

function levelInformatics() {
    return {
        windows: "",
        linux: "",
        sap: "",
        docs: "",
        spreadsheet: "",
        other: ""
    }
}

function levelGeneralMetalworkers() {
    return {
        forklifts: "",
        welding: "",
        mechanical_presses: "",
        molds_on_press: "",
        mold_construction: "",
        manual_control: "",
        cnc: "",
        robot: ""
    }
}

function newExperience() {
    return {
        from: "",
        to: "",
        company: "",
        task: ""
    }
}

function checkIsUndefined(obj) {
    return (obj === null || obj === undefined)
}

function checkIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || obj === "" || obj === " ")
}

function checkArrayIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || obj.length === 0)
}

function checkObjectIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || Object.keys(obj).length === 0)
}

function checkAreEquals(obj1, obj2) {
    return (!checkIsUndefined(obj1) && obj1 === obj2)
}

function findObject(array, value, key) {
    return array.find(el => el[key] === value)
}

function findObjectDownload(array, value, key) {
    return array.find(el => !checkIsUndefinedOrEmpty(el[key]) && el[key].includes(value))
}

function findObjectLowercase(array, value, key) {
    return array.find(el => el[key].toLowercase() === value)
}

function findIndexWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function deleteElementArray(array, index) {
    array.splice(index, 1)
}

function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
}

async function getCategories() {
    var stringCategories = getStringFilter(constants.COLUMNS_CATEGORY, true)
    var categories = []
    await CatalogService.fetchCategories(stringCategories)
        .then(resp => {
            categories = resp.data.data
            // var index = findIndexWithAttr(this.dataFilters, "id", "category")
            // this.dataFilters[index].list = resp.data.data
        })
        .catch(err => {
            console.log(err)
        })
    return categories
}