<script>
const sidebarMenu = [
  { path: '/products', icon: 'fa fa-tools', title: "message.products" },
  { path: '/company', icon: 'fa fa-tools', title: "message.company" },
  { path: '/news', icon: 'fa fa-newspaper', title: "message.news" },
  { path: '/download', icon: 'fa fa-download', title: "message.download" },
  { path: '/curriculum', icon: 'fa fa-file-code', title: "message.curriculum" },
  { path: '/contacts', icon: 'fa fa-address-book', title: "message.contacts" }
]

export default sidebarMenu;
</script>
