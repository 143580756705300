<template>
    <div class="row">
        <p class="title-section mb-0 p-0">{{ $t("message.productsCategories") }}</p>
        <swiper class="swiper visible-pagination px-0" ref="swiper" :options="swiperOption" @click-slide="setCategory">
            <swiper-slide v-for="category in categories" :key="category.id" >
                <div class="swiper-zoom-container">
                    <div class="px-1 custom-card pb-0 h-100 w-100 cursor-pointer">
                        <div class="h-100">
                            <img class="h-70 card-img-top img-news object-cover" :src="category.cover_image" alt="" />
                            <p class="h-20 gray-text text-center mb-0 px-3 line-height-15">{{ category.title[selectedLanguage.locale] }}</p>
                            <div class="mx-4 mx-md-5 h-10" :style="getBorder(category.color)">
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

        </swiper>
        <!-- <div class="col-4 col-xl-2 col-sm-4 px-1 pb-0 align-self-stretch" v-for="category in categories" :key="category.id">
            <div class="px-1 custom-card pb-0 h-100 cursor-pointer" @click="setCategory(category)">
                <div class="h-100">
                    <img class="h-50 h-md-70 card-img-top img-news object-cover" :src="category.cover_image" alt="" />
                    <p class="h-40 h-md-20 gray-text text-center mb-0 px-3 line-height-15">{{ category.title[selectedLanguage.locale] }}</p>
                    <div class="mx-4 mx-md-5 h-10" :style="getBorder(category.color)">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import constants from '../../config/constants.js'
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import CatalogService from '../../api/CatalogService.js'
import loader from '../../config/loader.js'
import { mapGetters } from 'vuex'
// import utils from '../../utils/utils.js'
import CatalogService from '../../api/CatalogService.js'
import utils from '../../utils/utils.js'
export default {
    name: "Categories",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            constants,
            categories: [],
            index: 0,
            visible: false,
            swiperOption: {
                // initialSlide: 2,
                zoom: false,
                loop: true,
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        initialSlide: 2,
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                        initialSlide: 2,
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    // when window width is >= 640px
                    640: {
                        initialSlide: 2,
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    // when window width is >= 640px
                    769: {
                        initialSlide: 2,
                        slidesPerView: 6,
                        spaceBetween: 15
                    }
                }
            },
        }
    },
    computed: {
        ...mapGetters(["selectedLanguage", "isLogged"])
    },
    async mounted() {
        loader.showLoader()
        await CatalogService.fetchCategories()
            .then(resp => {
                this.categories = resp.data.data
            })
            .catch(err => {
                console.log(err)
            })
        if(this.isLogged) {
            await CatalogService.fetchCategoriesReserved()
                .then(resp => {
                    this.categories = this.categories.concat(resp.data.data)
                    // this.categories = [...Object.assign([], this.categories), ...resp.data.data]
                })
                .catch(err => {
                    console.log(err)
                })
        }
        // console.log(this.$refs.swiper.swiperInstance.slideTo(6))
        loader.hideLoader()
    },
    methods: {
        setCategory(index, reallyIndex) {
            const category = this.categories[reallyIndex]
            if(utils.checkObjectIsUndefinedOrEmpty(category)){
                return
            }
            this.$router.push({name: "Products", params: {locale: this.selectedLanguage.locale}, query: {category: category.id}})
        },
        getBorder(color) {
            return `border-bottom: 5px solid ${color} !important;`
        }
    }
}
</script>