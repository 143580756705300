<template>
	<div data-app id="app">
		<!-- BEGIN #header -->
		<div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
			<div class="container px-2">
				<!-- BEGIN navbar-header -->
				<div class="navbar-header">
					<button type="button" class="navbar-mobile-toggler ml-1" @click.stop="toggleSidebarMobile"
						v-if="!appOptions.appSidebarNone">
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
					</button>
					<router-link :to="{name: 'Home', params}" class="navbar-brand ps-xl-0">
						<img src="@/assets/images/sm_logo_def_rgb.svg" height="90%" alt=""></router-link>
					<search class="no-mobile"/>
					<!-- <div class="input-group mr-3 no-mobile flex-nowrap">
						<vue-select class="flex-1" label="description" :filterable="false" :options="options"
							v-model="product" :clearSearchOnBlur="clearSearchOnBlur" :clearSearchOnSelect="true"
							:placeholder="$t('message.placeholderSearchBy')" @open="() => product = null"
							@search="onSearch" @input="goToDetail">
							<template slot="no-options">
								<span
									v-if="utils.checkObjectIsUndefinedOrEmpty(product)">{{ $t('message.placeholderSearchBy') }}</span>
								<span v-else>{{ $t("message.noSearch") }}</span>
							</template>
							<template #open-indicator="{ attributes }">
								<span v-bind="attributes"></span>
							</template>
							<template slot="option" slot-scope="option">
										<img width="100" height="100" :src="option.thumbnail" />
									<span class="long-name-search">{{ option.description[selectedLanguage.locale] }}</span>
							</template>
							<template slot="selected-option" slot-scope="option">
								<div class="selected d-center" v-html="option.description[selectedLanguage.locale]">
								</div>
							</template>
						</vue-select>
						<div class="input-group-append flex-shrink-1">
							<button class="btn btn-outline-primary btn-primary-no-hover no-cursor" type="button">
								<img src="@/assets/icons/cerca_azzurro.svg" class="image-icon-20" alt=""></button>
						</div>
					</div> -->

					<div class="navbar-nav justify-content-end">
						<!-- <router-link type="button" class="btn btn-primary no-wrap" :to="{name: 'CustomerArea'}">
						<span class="no-mobile">{{ $t("message.customerArea") }}</span> <i class="fa fa-user"></i>
					</router-link> -->
						<b-nav-item-dropdown right menu-class="me-1" class="navbar-item navbar-user dropdown"
							toggle-class="navbar-link w-155 p-0 dropdown-toggle d-flex align-items-center" no-caret>
							<template slot="button-content">
								<a type="button" class="btn btn-primary no-wrap font-size-100 w-100">
									<span class="no-mobile long-name">{{ name }}</span>
									<!-- <i class="fa fa-user"></i> -->
									<img src="@/assets/icons/area_clienti.svg" class="image-icon ms-lg-2 me-0"
										alt="">
								</a>
							</template>
							<b-dropdown-item v-if="!isLogged" @click="$router.push({name: 'CustomerAreaLogin', params})"
								class="dropdown-item">
								{{ $t("message.signIn") }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isLogged" @click="$router.push({name: 'Profile', params})"
								class="dropdown-item">
								{{ $t("message.editProfile") }}
							</b-dropdown-item>
							<div class="dropdown-divider"></div>
							<b-dropdown-item v-if="!isLogged" @click="$router.push({name: 'CustomerAreaLogin', params})"
								class="dropdown-item">
								{{ $t("message.signUp") }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isLogged" @click="logout" class="dropdown-item">
								{{ $t("message.logout") }}
							</b-dropdown-item>
						</b-nav-item-dropdown>

						<langs />
					</div>
				</div>
				<!-- END navbar-header -->


				<!-- BEGIN header-nav -->
				<div class="navbar-nav no-mobile">
					<header-mega-menu class="mt-3" v-if="appOptions.appHeaderMegaMenu"></header-mega-menu>

					<!-- <b-nav-item-dropdown v-if="!utils.checkIsUndefinedOrEmpty(user)" right menu-class="me-1" class="navbar-item navbar-user dropdown" toggle-class="navbar-link dropdown-toggle d-flex align-items-center" no-caret>
					<template slot="button-content">
						<div class="image image-icon bg-gray-800 text-gray-600">
							<i class="fa fa-user"></i>
						</div>
						<span class="d-none d-md-inline">{{ `${user.first_name} ${user.last_name}` }}</span> <b class="caret"></b>
					</template>
					<a @click="$router.push({name: 'Profile'})" class="dropdown-item">Edit Profile</a>
					<div class="dropdown-divider"></div>
					<a href="javascript:;" class="dropdown-item">Log Out</a>
				</b-nav-item-dropdown> -->
					<div class="navbar-divider d-none d-md-block" v-if="appOptions.appSidebarTwo"></div>
					<div class="navbar-item d-none d-md-block" v-if="appOptions.appSidebarTwo">
						<a href="javascript:;" v-on:click="toggleSidebarEnd" class="navbar-link icon">
							<i class="fa fa-th"></i>
						</a>
					</div>
				</div>
				<!-- end header navigation right -->
			</div>
			<!-- end #header -->
		</div>
	</div>
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import utils from '../../utils/utils.js'
import { mapGetters } from 'vuex'
import Langs from '../langs/Langs.vue'
import CatalogService from '../../api/CatalogService'

export default {
	name: 'Header',
	components: {
		HeaderMegaMenu,
Langs
	},
	data() {
		return {
			appOptions: AppOptions,
			utils: utils,
			options: [],
			product: null,
			timer: null
		}
	},
	computed: {
		...mapGetters(["user", "selectedLanguage"]),
		isLogged() {
			return !utils.checkObjectIsUndefinedOrEmpty(this.user)
		},
		params() {
			return {
				locale: this.selectedLanguage.locale
			}
		},
		name() {
			if(utils.checkObjectIsUndefinedOrEmpty(this.user)){
				return this.$t("message.customerArea")
			}
			// if(utils.checkObjectIsUndefinedOrEmpty(this.user.extra)) {
			// 	return `${this.user.email}`
			// }
			// if(utils.checkIsUndefinedOrEmpty(this.user.extra.company)) {
			// 	return `${this.user.email}`
			// }
			return this.user.email
		}
	},
	methods: {
		clearSearchOnBlur() {
			return false
		},
		goToDetail(element) {
			if(utils.checkObjectIsUndefinedOrEmpty(element)) {
				return
			}
			this.$router.push({name: "DetailProduct", params: {locale: this.selectedLanguage.locale, id: element.id}})
		},
		async onSearch (search, loading) {
			loading(true)
			if(!utils.checkIsUndefinedOrEmpty(this.timer)){
				clearTimeout(this.timer)
			}
			this.timer = setTimeout(async () => {
				if(!utils.checkIsUndefinedOrEmpty(search)) {
					await CatalogService.search(search)
						.then(resp => {
							this.options = resp.data.articles
						})
						.catch(err => {
							console.log(err)
						})
				} else {
					this.options = []
				}
				loading(false)
			}, 1000)
			//  ... do some asynchronous stuff!  
		},
		logout() {
			this.$store.dispatch("logout");
		},
		toggleSidebarMobile() {
			this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled;
		},
		toggleSidebarEnd() {
			this.appOptions.appSidebarEndToggled = !this.appOptions.appSidebarEndToggled;
		},
		toggleSidebarEndMobile() {
			this.appOptions.appSidebarEndMobileToggled = !this.appOptions.appSidebarEndMobileToggled;
		},
		toggleTopMenuMobile() {
			this.appOptions.appTopMenuMobileToggled = !this.appOptions.appTopMenuMobileToggled;
		},
		toggleHeaderMegaMenuMobile() {
			this.appOptions.appHeaderMegaMenuMobileToggled = !this.appOptions.appHeaderMegaMenuMobileToggled;
		},
		checkForm: function (e) {
			e.preventDefault();
			this.$router.push({
				path: '/extra/search'
			})
		}
	}
}
</script>
