<template>
    <div>
        <swiper class="swiper"
                :class="[orientation === constants.IMAGE_HORIZONTAL ? 'h-auto' : '', isLeft ? 'me-0 me-md-5' : 'ms-0 ms-md-5']"
                :options="swiperOption">
            <swiper-slide class="swiper visible-pagination" v-for="(image, index) in images" :key="image.id">
                <div class="swiper-zoom-container ">
                    <img class="custom-card" @click="show(index)" :src="utils.getPublicFile(image.image)">
                </div>
            </swiper-slide>
            <!-- <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div> -->
            <div v-if="images.length > 1" class="swiper-button-prev" slot="button-prev"></div>
            <div v-if="images.length > 1" class="swiper-button-next" slot="button-next"></div>

        </swiper>
        <vue-easy-lightbox :visible="visible" :imgs="onlyImages" :index="index" @hide="handleHide" @on-next="changeImg"
                           @on-prev="changeImg"></vue-easy-lightbox>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import constants from '../../config/constants.js'
import 'swiper/css/swiper.css'
import {
    mapGetters
} from 'vuex'
import AuditService from '../../api/AuditService.js'
import utils from '../../utils/utils.js'

export default {
    name: 'swiper-example-zoom',
    title: 'Zoom',
    components: {
        Swiper,
        SwiperSlide
    },
    props: ["images", "orientation", "article_id", "isLeft"],
    data() {
        return {
            utils,
            constants,
            swiperOption: {
                zoom: false,
                spaceBetween: 20,
                // pagination: {
                //   el: '.swiper-pagination'
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            index: 0,
            visible: false,
        }
    },
    methods: {
        changeImg(oldIndex, newIndex) {
            this.index = newIndex
            this.auditImage()
        },
        async auditImage() {
            if (this.isLogged) {
                let data = new FormData()
                data.append("data", utils.stringLogArticleWithImage(this.article_id, this.images[this.index].id))
                await AuditService.newLog(constants.CATALOG_VIEW_ARTICLE_IMAGE, data)
                    .then(() => {
                        console.log(`success: ${constants.CATALOG_VIEW_ARTICLE_IMAGE}`)
                    })
                    .catch(() => {
                        console.log(`error: ${constants.CATALOG_VIEW_ARTICLE_IMAGE}`)
                    })
            }
        },
        show(index) {
            this.index = index
            this.visible = true
            this.auditImage()
        },
        handleHide() {
            this.visible = false
        }
    },
    computed: {
        ...mapGetters([
            "isLogged"
        ]),
        onlyImages() {
            let array = []
            this.images.forEach(image => {
                array.push(utils.getPublicFile(image.image))
            });
            return array
        }
    }
}
</script>

<style lang="scss" scoped>
.swiper {
  // height: 600px;
  .swiper-slide {
    background: #444;
  }
}
</style>