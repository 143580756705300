// import constants from '../config/constants.js'
import store from '../store/store.js'
import axios from 'axios';
import utils from '../utils/utils.js'

const axiosApi = axios.create({
    baseURL: utils.baseURL()
})

axiosApi.setAuthHeaderWithToken = function(token) {
    if(token === undefined) {
        token = store.state.token
    }
    if(!utils.checkIsUndefinedOrEmpty(token)) {
        axiosApi.defaults.headers.common['Authorization'] = 'JWT ' + token
    }
}

export default() => {
    return axiosApi
}