import Api from './index.js'
// import store from '../store/store.js'

export default {
    fetchTranslations(extra = "") {
        return Api().get('api/translations/?format=datatables' + extra)
    },
    getTranslation(id) {
        return Api().get(`api/translations/${id}/`)
    }
}
