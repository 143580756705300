<template>
    <panel :title="$t('message.educationalQualification', 'it')">
        <div class="row">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.primarySchool', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="study_primary_school" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.middleSchool', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="study_middle_school" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.diploma', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_diploma" maxlength="50" type="text" class="form-control" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.graduation', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_degree" type="text" maxlength="50" class="form-control" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.other', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_other" type="text" maxlength="50" class="form-control" />
            </div>
        </div>
    </panel>
</template>

<script>
function initialState() {
    return {
        study_primary_school: false,
        study_middle_school: false,
        study_diploma: "",
        study_degree: "",
        study_other: ""
    }
}

export default {
    data() {
        return initialState()
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>