<template>
  <div>
    <!-- TOOLBAR -->
    <v-toolbar dark color="primary" fixed v-if="searchable">
      <v-toolbar-title class="white--text">{{ $t("message.search") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
      <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
        <v-list>
          <v-list-tile v-for="(item) in columns" :key="item.value" @click="changeSort(item.value)">
            <v-list-tile-title>{{ item.text }}
              <v-icon v-if="pagination.sortBy === item.value">
                {{ pagination.descending ? 'arrow_downward' : 'arrow_upward' }}
              </v-icon>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-layout v-resize="onResize" column>
      <v-expansion-panels multiple accordion class="only-mobile" v-model="panel">
        <v-expansion-panel
            v-for="(item,i) in data"
            :key="i"
        >
          <v-expansion-panel-header
              :class="[item.on_request ? 'bg-gray' : '', 'mh-58', item.code === codeComponent  ? 'search-code-col': '']">
            {{ getString(header[0].title) }}
            <strong class="text-end">
              <a class="btn btn-info no-bg py-0" @click.stop="downloadPdf(item)" v-if="item.file_present">
                <img height="100%" src="@/assets/icons/tavola_pdf.svg" class="image-icon-20" alt=""></a>
              <span v-html="getCode(item)"></span></strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3">

            <div v-for="(col, index) in header" :key="col.id +index"
                 v-show="!col.title.toUpperCase().includes('CODE') && !col.title.toUpperCase().includes('CODICE')">
              <div
                  v-if="col.title.trim().toUpperCase() === 'DIAMETER' || col.title.trim().toUpperCase() === 'DIAMETRI' || col.title.trim().toUpperCase() === 'DIAMETRO'">
                <div
                    v-if="(getData(col.children, item.data).includes('(') && getData(col.children, item.data).includes(')')) || col.cols === 7">
                  <p class="flex-strong"><strong>{{ getString(col.title) }}</strong></p>
                  <p class="flex-strong">[mm] <strong class="text-end"
                                                      v-html="getDiameter(col.children, item.data, 1, col.cols)"></strong>
                  </p>
                  <p class="flex-strong">[in] <strong class="text-end"
                                                      v-html="getDiameter(col.children, item.data, 0, col.cols)"></strong>
                  </p>
                  <!-- <v-expansion-panels multiple accordion class="only-mobile mb-4" :value="[0]" >
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>
                        INCH
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <strong v-html="getData(col.children, item.data).split('(')[1].replace(')', '')"></strong>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>
                        MM
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <strong v-html="getData(col.children, item.data).split('(')[0]"></strong>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels> -->
                </div>
                <p v-else class="flex-strong"><strong>{{ getString(col.title) }}</strong> <strong class="text-end"
                                                                                                  v-html="getData(col.children, item.data)"></strong>
                </p>
              </div>
              <div v-else>
                <p class="flex-strong" v-if="col.children.length === 0"><strong>{{
                    getString(col.title)
                  }}</strong> <strong class="text-end" v-html="item[col.title][0]"></strong></p>
                <p class="flex-strong"
                   v-else-if="utils.checkIsUndefinedOrEmpty(getTitles(col.children)) || getTitles(col.children).trim().length === 0">
                  <strong>{{ getString(col.title) }}</strong> <strong class="text-end"
                                                                      v-html="getData(col.children, item.data)"></strong>
                </p>
                <div v-else>
                  <p class="flex-strong"><strong v-html="getString(col.title)"></strong></p>
                  <!-- <p class="flex-strong">{{ getTitles(col.children) }} <strong class="text-end" v-html="getData(col.children, item.data)"></strong></p> -->
                  <p v-for="(child) in col.children" :key="child.id" class="flex-strong">{{ child.title }} <strong
                      class="text-end" v-html="item.data[child.id]"></strong></p>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- DATATABLE -->
      <v-data-table class="no-mobile" :headers="columns" hide-default-header :items="data" :search="search"
                    disable-pagination :hide-default-footer="!isPagination" :disable-sort="!sortable">
        <template slot="header">
          <thead>

          <tr class="first-tr-head">
            <td></td>
            <td class="text-center divider-table font-weight-bold no-wrap"
                :class="{'code': el.title.toUpperCase() === 'CODE' || el.title.toUpperCase() === 'CODICE'}"
                v-for="(el, index) in header"
                :key="el.title + index" :colspan="el.cols" v-html="getString(el.title)"></td>
          </tr>
          <tr>
            <td></td>
            <td class="font-weight-bold"
                :class="[calcAlign(el) === '' ? 'text-center' : calcAlign(el)]"
                v-for="el in secondRowHeader" :key="el.id" :colspan="el.cols">{{ el.title }}
            </td>
          </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="(item, key) in items" :key="key" :class="item.on_request ? 'bg-gray' : ''">
            <td>
              <a class="btn" @click="downloadPdf(item)" v-if="item.file_present">
                <img height="100%" src="@/assets/icons/tavola_pdf.svg" class="image-icon-24" alt=""></a>
            </td>
            <td v-for="(col, i) in secondRowHeader"
                :class="[item.code === codeComponent && i == 0  ? 'search-code-col': '', calcAlign(col), col.parent.toUpperCase() === 'CODE' || col.parent.toUpperCase() === 'CODICE' ? 'code' : '']"
                class="td-text-center" :key="i" v-html="item.data[col.id]"></td>
          </tr>
          </tbody>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import loader from '../../config/loader';
import swal from '../../config/swal';
import utils from '../../utils/utils';
import {
  mapGetters
} from 'vuex'
import constants from '../../config/constants';
import AuditService from '../../api/AuditService';
import CatalogService from '../../api/CatalogService';
import notification from '../../config/notification'

export default {
  props: ['searchable', 'isPagination', 'sortable', 'columns', "data", "header", "article_id"],
  data: () => ({
    utils,
    pagination: {
      sortBy: 'code'
    },
    selected: [],
    search: '',
    isMobile: false,
    secondRowHeader: [],
    panel: [0]
  }),

  mounted() {
    this.watchHeader()
  },
  watch: {
    header: {
      deep: true,
      handler: function () {
        this.watchHeader()
      }
    }
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "translations",
      "selectedLanguage"
    ]),
    // dataWithValue() {
    //   var array = []
    //   this.data.forEach(element => {
    //     var toPass = {}
    //     toPass["id"] = element.id
    //     toPass["code"] = element.code
    //     toPass["file_present"] = element.file_present
    //     toPass["on_request"] = element.on_request
    //     var passed = []
    //     this.header.forEach(he => {
    //       var actual = he.cols
    //       for(var key in element.data){
    //         var value = element.data[key]
    //         if(actual > 0 && !passed.includes(key)) {
    //           if(utils.checkIsUndefined(toPass[he.title])){
    //             toPass[he.title] = [value]
    //           } else {
    //             toPass[he.title].push(value)
    //           }
    //           passed.push(key)
    //           actual--
    //         }
    //       }

    //     });
    //     array.push(toPass)
    //   });
    //   return array
    // },
    codeComponent() {
      if (utils.checkIsUndefinedOrEmpty(this.$route.query.code)) {
        return ""
      }
      return this.$route.query.code
    },
    errorComponentPdf() {
      if (this.translations.length === 0) {
        return this.$t("message.errorComponentPdf")
      }
      var object = this.translations.find(e => e.key.toUpperCase() === constants.COMPONENT_PDF_ERROR.toUpperCase())
      // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
      if (utils.checkObjectIsUndefinedOrEmpty(object)) {
        return this.$t("message.errorComponentPdf")
      }
      return object.value[this.selectedLanguage.locale]
    }
  },

  methods: {
    calcAlign(col) {
      if (col.parent.trim().toUpperCase() !== 'DIAMETER' && col.parent.trim().toUpperCase() !== 'DIAMETRI' && col.parent.trim().toUpperCase() !== 'DIAMETRO') {
        return ''
      }
      if ((col.total === 3 && col.index === 1) || col.total === 1) {
        return 'text-center diameter'
      }
      if (col.index === 0 || (col.total === 7 && col.index === 4) || (col.total === 6 && col.index === 3)) {
        return 'text-right diameter'
      }
      if (col.index === 2 || (col.total === 7 && col.index === 6) || (col.total === 6 && col.index === 5)) {
        return 'text-left diameter'
      }
      return 'diameter'
    },
    getTitles(child) {
      var string = ""
      child.forEach(element => {
        string += `${element.title} `
      });
      return string
    },
    getData(child, data) {
      var string = ""
      child.forEach(element => {
        if (utils.checkIsUndefinedOrEmpty(data[element.id])) {
          return
        }
        string += `${data[element.id]} `
      });
      return string
    },
    getDiameter(child, data, index, colsLenght) {
      let temp = this.getData(child, data)
      if (utils.checkIsUndefinedOrEmpty(temp)) {
        return ''
      }
      if (colsLenght === 7 && temp.split('(').length === 1) {
        switch (true) {
          case index === 0 && temp.includes("\""):
            return temp
          case index === 0 && !temp.includes("\""):
            return ''
          case index === 1 && !temp.includes("\""):
            return temp
          case index === 1 && temp.includes("\""):
            return ''
          default:
            return ''
        }
      }
      let tempSplit = temp.split('(')[index]
      if (utils.checkIsUndefinedOrEmpty(tempSplit)) {

        return ''
      }

      return tempSplit.replace(')', '')
    },
    watchHeader() {
      this.panel = []
      this.header.forEach((element, index) => {
        this.panel.push(index)
        if (element.children.length === 0) {
          return
        }
        element.children.forEach((child, i) => {
          var cols = Object.assign({}, child)
          cols['parent'] = element.title
          cols['total'] = element.children.length
          cols['index'] = i
          this.secondRowHeader.push(cols)
        });
      });
    },
    getString(key) {
      if (this.translations.length === 0) {
        return key
      }
      var object = this.translations.find(e => e.key.toUpperCase() === key.toUpperCase())
      if (utils.checkObjectIsUndefinedOrEmpty(object)) {
        return key
      }
      return utils.decodeHtml(object.value[this.selectedLanguage.locale].replace(/<\/[^>]+>/gi, "").replace(/<[^>]+>/gi, "").replace(/\n/g, "<br>"))
    },
    getCode(item) {
      for (var key in item) {
        if (constants.ARRAY_CODE.includes(key.toLowerCase())) {
          return item[key]
        }
      }
      return ""
    },
    async downloadPdf(item) {
      if (!this.isLogged) {
        swal.errorWithTitle(this.$t("message.warningAlert"), this.errorComponentPdf)
        return
      }
      loader.showLoader()
      // window.open(`${utils.baseURL()}/api/catalog/download/component/${id}/`, "_blank")
      await CatalogService.downloadComponent(item.id)
          .then(resp => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var blob = new Blob([resp.data], {type: "application/pdf"})
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${item.code}`;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(err => {
            notification.notifyError("Errore durante il download")
            console.log(err)
          })
      if (this.isLogged) {
        let data = new FormData()
        data.append("data", utils.stringLogArticleWithComponent(this.article_id, item.id))
        await AuditService.newLog(constants.CATALOG_DOWNLOAD_ARTICLE_COMPONENT_PDF, data)
            .then(() => {
              console.log(`success: ${constants.CATALOG_DOWNLOAD_ARTICLE_COMPONENT_PDF}`)
            })
            .catch(() => {
              console.log(`error: ${constants.CATALOG_DOWNLOAD_ARTICLE_COMPONENT_PDF}`)
            })
      }
      loader.hideLoader()
    },
    onResize() {
      if (window.innerWidth < 769)
        this.isMobile = true;
      else
        this.isMobile = false;
    },
    toggleAll() {
      if (this.selected.length) this.selected = []
      else this.selected = this.desserts.slice()
    },
    changeSort(column) {
      console.log(column);
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mobile {
  color: #333;
}

.first-tr-head {
  vertical-align: top;

  td {
    padding-top: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: .5em;
    text-align: left;
    display: block;
    color: #999;

  }

  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }

  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }

}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}
</style>