<template>
  <div class="row">
    <div class="d-flex mb-2 align-items-center justify-content-between">
      <p class="title-section mb-0">{{ $t("message.download") }}</p>
      <button @click="refresh" class="btn btn-primary h-fit-content">
        <i class="fa fa-sync"></i>
      </button>
    </div>

    <div class="col-md-6 col-sm-12 col-lg-6 col-xl-4" v-for="element in getArrayImage(true)" :key="element.id"
         v-show="!utils.checkObjectIsUndefinedOrEmpty(element.files[selectedLanguage.locale]) ||
         (!utils.checkIsUndefinedOrEmpty(element.tag) && element.tag.includes(constants.RESERVED_CATALOG_TAG))">
      <div class="card custom-card h-100">
        <img class="card-img-top" :src="element.cover_image" alt="Card image cap">
        <div class="card-body d-flex flex-column">
          <p class="title-section mb-3 line-height-1">{{ element.title[selectedLanguage.locale] }}</p>
          <p v-if="utils.checkIsUndefinedOrEmpty(element.summary[selectedLanguage.locale])" class="card-text">
            &nbsp; </p>
          <p v-else class="card-text">{{ element.summary[selectedLanguage.locale] }}</p>
          <a @click="download(element)" class="btn btn-primary button-md-bottom">{{
              $t("message.downloadFile")
            }}</a>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card custom-card h-100 w-100 row flex-row" v-if="getArrayImage(false).length > 0 || isLogged">
        <div v-for="(element, index) in getArrayImage(false)" :key="index"
             class="text-18 padding-12 col-12 col-md-6"
             v-show="!utils.checkObjectIsUndefinedOrEmpty(element.files[selectedLanguage.locale])">
          <img @click="download(element)" src="@/assets/icons/download_pdf.svg"
               class="cursor-pointer image-icon-36 me-3" alt="">
          <!-- <i @click="download(element.file)" class="fa fa-file-arrow-down text-24 mr-3 mb-3 cursor-pointer"></i> -->
          <span @click="download(element)" v-if="utils.checkIsUndefined(element.title)"
                class="cursor-pointer">{{ element.name[selectedLanguage.locale] }}</span>
          <span @click="download(element)" v-else
                class="cursor-pointer">{{ element.title[selectedLanguage.locale] }}</span>
        </div>
        <div class="text-18 padding-12 col-12 col-md-6" v-if="thereIsReserved">
          <img @click="getReservedCatalog" src="@/assets/icons/download_pdf.svg"
               class="image-icon-36 me-3 cursor-pointer" alt="">
          <!-- <i @click="download(element.file)" class="fa fa-file-arrow-down text-24 mr-3 mb-3 cursor-pointer"></i> -->
          <span @click="getReservedCatalog" class="cursor-pointer">{{
              $t("message.getReservedCatalog")
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DownloadService from "../api/DownloadService.js"
import {mapGetters} from 'vuex'
import utils from '../utils/utils.js'
import loader from '../config/loader.js'
import constants from '../config/constants.js'
import DownloadService from '../api/DownloadService.js'
import AuditService from '../api/AuditService.js'

export default {
  metaInfo() {
    return {
      title: this.$t("message.download"),
      titleTemplate: constants.TITLE_TEMPLATE
    }
  },
  data() {
    return {
      dataDownload: [],
      utils,
      constants
    }
  },
  async mounted() {
    loader.showLoader()
    // await DownloadService.fetchDownloads()
    //   .then(resp => {
    //     this.dataDownload = resp.data.data
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    // if(this.isLogged) {
    //   await DownloadService.fetchDownloadsReserved()
    //     .then(resp => {
    //       this.dataDownload = this.dataDownload.concat(resp.data.data)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // }
    loader.hideLoader()
  },
  methods: {
    async refresh() {
      loader.showLoader()
      await DownloadService.fetchDownloads()
          .then(resp => {
            this.$store.commit("setDownloads", resp.data.data)
          })
          .catch(err => {
            console.log(err)
          })
      if (this.isLogged) {
        await DownloadService.fetchDownloadsReserved()
            .then(resp => {
              this.$store.commit("pushDownloads", resp.data.data)
            })
            .catch(err => {
              console.log(err)
            })
        await DownloadService.fetchPriceList()
            .then(resp => {
              this.$store.commit("pushDownloads", resp.data.data)
            })
            .catch(err => {
              console.log(err)
            })
      }
      loader.hideLoader()
    },
    async getReservedCatalog() {
      let params = {
        lang: this.selectedLanguage.locale
      }
      loader.showLoader()
      await DownloadService.getReservedCatalog(params)
          .then(async (resp) => {
            utils.downloadFile(resp)
            if (this.isLogged) {
              let data = new FormData()
              data.append("data", JSON.stringify({}))
              await AuditService.newLog(constants.CATALOG_DOWNLOAD_RESERVED_CATALOG, data)
                  .then(() => {
                    console.log(`success: ${constants.CATALOG_DOWNLOAD_RESERVED_CATALOG}`)
                  })
                  .catch(() => {
                    console.log(`error: ${constants.CATALOG_DOWNLOAD_RESERVED_CATALOG}`)
                  })
            }
          })
          .catch(err => {
            console.log(err)
          })
      loader.hideLoader()
    },
    async download(element) {
      loader.showLoader()
      if (!utils.checkIsUndefinedOrEmpty(element.tag) && element.tag.includes(constants.RESERVED_CATALOG_TAG)) {
        this.getReservedCatalog()
        return
      }
      var data = {}
      if (utils.checkIsUndefinedOrEmpty(element.reserved) && element.is_price_list) {
        await DownloadService.downloadPriceList(element.id, this.selectedLanguage.locale)
            .then(async resp => {
              data = resp
              if (this.isLogged) {
                let data = new FormData()
                data.append("data", JSON.stringify({
                  price_list_id: element.id
                }))
                await AuditService.newLog(constants.DOWNLOAD_PRICE_LIST, data)
                    .then(() => {
                      console.log(`success: ${constants.DOWNLOAD_PRICE_LIST}`)
                    })
                    .catch(() => {
                      console.log(`error: ${constants.DOWNLOAD_PRICE_LIST}`)
                    })
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else if (element.reserved) {
        await DownloadService.downloadReservedDownload(element.id, this.selectedLanguage.locale)
            .then(async (resp) => {
              data = resp
              if (this.isLogged) {
                let data = new FormData()
                data.append("data", JSON.stringify({
                  reserved_download_id: element.id
                }))
                await AuditService.newLog(constants.DOWNLOAD_RESERVED_DOWNLOAD, data)
                    .then(() => {
                      console.log(`success: ${constants.DOWNLOAD_RESERVED_DOWNLOAD}`)
                    })
                    .catch(() => {
                      console.log(`error: ${constants.DOWNLOAD_RESERVED_DOWNLOAD}`)
                    })
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        await DownloadService.downloadDownload(element.id, this.selectedLanguage.locale)
            .then(async (resp) => {
              data = resp
              if (this.isLogged) {
                let data = new FormData()
                data.append("data", JSON.stringify({
                  download_id: element.id
                }))
                await AuditService.newLog(constants.DOWNLOAD_DOWNLOAD, data)
                    .then(() => {
                      console.log(`success: ${constants.DOWNLOAD_DOWNLOAD}`)
                    })
                    .catch(() => {
                      console.log(`error: ${constants.DOWNLOAD_DOWNLOAD}`)
                    })
              }
            })
            .catch(err => {
              console.log(err)
            })
      }
      utils.downloadFile(data)
      loader.hideLoader()
    },
    getArrayImage(withImage) {
      var array = []
      if (!utils.checkArrayIsUndefinedOrEmpty(this.downloads)) {
        this.downloads.forEach(download => {
          if (utils.checkIsUndefinedOrEmpty(download.cover_image) !== withImage &&
              ((!utils.checkObjectIsUndefinedOrEmpty(download.files) &&
                  !utils.checkObjectIsUndefinedOrEmpty(download.files[this.selectedLanguage.locale])) ||
                  (!utils.checkIsUndefinedOrEmpty(download.tag) && download.tag.includes(constants.RESERVED_CATALOG_TAG))
              )
          ) {
            array.push(download)
          }
        });
      }
      return array
    }
  },
  computed: {
    thereIsReserved() {
      if (!this.isLogged) {
        return false
      }
      let noReserved = true
      this.downloads.forEach(download => {
        if (utils.checkIsUndefinedOrEmpty(download.tag)) {
          return
        }
        if (!utils.checkIsUndefinedOrEmpty(download.tag) && download.tag.includes(constants.RESERVED_CATALOG_TAG)) {
          noReserved = false
        }
      });
      return noReserved
    },
    ...mapGetters([
      "selectedLanguage",
      "isLogged",
      "downloads"
    ]),

  }
}
</script>