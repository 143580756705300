export default {
    english: "English",
    italian: "Italiano",
    german: "Deutsch",
    french: "Français",
    spanish: "Español",
    news: "Aktuelles",
    noNews: "Keine News",
    products: "Produkte",
    download: "Download",
    curriculum: "Lebenslauf",
    customerArea: "Kundenbereich",
    contacts: "Kontakt",
    newProduct: "Neuer produkt",
    company: "Unternehmen",
    companyText1: "Dal 1963 la nostra Azienda, fondata dalla famiglia Barbieri, progetta e costruisce morsetti, raccordi, supporti ed una gamma completa di accessori ed attrezzature per la zootecnia ed impianti agricoli. I nostri prodotti trovano il loro più ampio utilizzo in questi settori e rispondono pienamente a tutte le esigenze degli allevamenti moderni, sempre più attenti al benessere dell'animale e del settore del vivaismo e della floricoltura.",
    companyText2: "La nostra realtà, completamente italiana, da sempre dedica particolare attenzione alle specifiche esigenze di ogni singolo cliente, selezionando materie prime di qualità e avvalendosi di un know-how di oltre cinquant'anni che ha permesso una continua evoluzione del prodotto e il deposito di alcuni brevetti.",
    companyText3: "Tutto il ciclo produttivo viene realizzato internamente partendo dalla progettazione e costruzione degli stampi fino allo sviluppo del prodotto finito mantenendo alti livelli di qualità e robustezza del nostro prodotto. La gamma si estende a numerosi prodotti sia standard che su misura per qualsiasi tipo di applicazione e con diverse finiture, zincatura a caldo o elettro galvanizzazione, che li rendono durevoli nel tempo e adatti ad ambienti corrosivi. Alcuni dei nostri articoli sono inoltre costruiti in acciaio inox.",
    companyText4: "Il nostro servizio commerciale, affiancato dall'ufficio tecnico e di progettazione, è disponibile per orientare la clientela nella selezione dei componenti più idonei da utilizzare nei singoli casi e nella realizzazione di soluzioni speciali per soddisfare al meglio ogni tipo di esigenza.",
    companyText5: "La nostra forza risiede nella rapida evasione dell'ordine in quanto la maggior parte dei nostri articoli e disponibile a magazzino: garantiamo consegne in 24/48 ore sul territorio nazionale e in tempi rapidi in tutti i Paesi europei. Il crescente successo e l'affermazione sui principali mercati europei ha incoraggiato l'impegno anche nella produzione di prodotti personalizzati per i grandi players internazionali del settore della zootecnia confermandoci come loro partner principale.",
    companyText6: "Ad oggi la nostra sede produttiva si estende per 11.340 mg. di superficie totale dei quali 6.244 mq. coperti ed impiega circa il 90% della propria energia utilizzando energia rinnovabile.",
    findOutMore: "Finde mehr heraus",
    usefulContacts: "Nützliche Kontakte", 
    where: "So finden Sie uns",
    hours: "Öffnungszeiten",
    fromMondayToFriday: "Montag bis Freitag",
    dateIssue: "Data di compilazione",
    availableHiring: "Disponibilità per assunzione dal",
    personalData: "Dati Anagrafici",
    firstName: "Nome",
    lastName: "Cognome",
    birthPlace: "Luogo di nascita",
    birthProvince: "Provincia di nascita",
    birthCountry: "Nazione di nascita",
    birthDate: "Data di nascita",
    placeResidence: "Luogo di residenza",
    postalCode: "PLZ",
    province: "Provincia",
    address: "Adresse",
    houseNumber: "N° Civico",
    nationality: "Nazionalità",
    telephone: "Telefonnummer",
    email: "E-Mail",
    family: "Nucleo familiare",
    father: "Padre",
    mother: "Madre",
    partner: "Coniuge/compagno",
    child: "Figlio",
    educationalQualification: "Titolo di studio",
    primarySchool: "Licenza elementare",
    middleSchool: "Licenza media",
    diploma: "Diploma",
    graduation: "Laurea",
    other: "Altro",
    sectorWork: "Richiesta settore di lavoro",
    administrationControl: "Amministrazione e controllo",
    commercialItaly: "Commerciale Italia",
    commercialAbroad: "Commerciale Estero",
    warehouseShipping: "Magazzino e spedizioni",
    packaging: "Imballaggio",
    tooling: "Attrezzeria",
    production: "Produzione",
    technicalOffice: "Ufficio tecnico",
    healthConditions: "Condizioni di salute",
    suitabilityAnyJob: "Idoneità a qualsiasi mansione",
    generalNews: "Notizie generali",
    ownCar: "Munito di proprio mezzo",
    driverLicense: "Patente di guida e tipo",
    detailLastTitle: "Dettagli ultimo titolo di studio conseguito",
    yearGraduation: "Anno di conseguimento",
    institute: "Istituto",
    city: "Stadt",
    finalVote: "Votazione finale",
    knowledgeForeignLanguages: "Conoscenza lingue straniere",
    home: "Startseite",
    back: "Zurück",
    next: "Fortsetzen",
    comprehension_listen: "COMPRENSIONE - Ascolto",
    comprehension_read: "COMPRENSIONE - Lettura",
    verbal_interaction: "PARLATO - Interazione",
    verbal_oral: "PARLATO - Produzione Orale",
    write: "SCRITTO",
    levelsLanguage: "Livelli: A1/2: Elementare - B1/2: Intermedio - C1/2 Avanzato",
    commonEuropean: "Quadro comune europeo di riferimento per la conoscenza delle lingue.",
    knowledges: "Conoscenze",
    nothing: "Nessuna",
    decent: "Discreta",
    great: "Ottima",
    technicalDesign: "Disegno Tecnico",
    informatics: "Informatiche",
    generalMetalworkers: "Metalmeccaniche Generali",
    drawing_read: "Sono in grado di leggere ed interpretare un disegno",
    drawing_draw: "Sono in grado di disegnare",
    drawing_cad: "Sono in grado di disegnare con l'ausilio di mezzi informatici (CAD/CAM)",
    windows: "Sistemi operativi Windows",
    linux: "Sistemi operativi Unix/Linux",
    sap: "SAP",
    docs: "Applicativi per scrittura",
    spreadsheet: "Fogli di calcolo",
    forklifts: "Utilizzo di carelli elevatori",
    welding: "Saldatura manuale",
    mechanical_presses: "Utilizzo di presse meccaniche",
    molds_on_press: "Montaggio stampi su pressa",
    mold_construction: "Costruzione/riparazione stampi",
    manual_control: "Utilizzo di macchine utensili a controllo manuale",
    cnc: "Programmazione di macchine utensili automatiche (CNC)",
    robot: "Programmazione di robot",
    experiences: "Esperienze",
    from: "Dal",
    to: "Al",
    companyWorked: "Azienda/Ente",
    task: "Mansione",
    italy: "Italia",
    abroad: "Estero",
    businessTrips: "Viaggi di lavoro (presso clienti o fornitori), Fiere e Manifestazioni",
    oneDay: "1 giorno",
    fiveDays: "5 giorni",
    beyond: "Oltre",
    contract: "Contratto",
    contractualExpectations: "Aspettative Contrattuali",
    contractualEconomics: "Aspettative Economiche",
    partTime: "Part-Time",
    apprenticeship: "Contratto di apprendistato",
    fixedTime: "Contratto a tempo determinato",
    indeterminateTime: "Contratto a tempo indeterminato",
    collaboration: "Collaborazione/consulenze",
    contractualMinimum: "Minimo contrattuale",
    salary: "Retribuzione mensile desiderata",
    monthlyPayments: "Numero di mensilità",
    gross: "Lorda",
    net: "Netta",
    lastSalary: "Ultima Retribuzione Netta",
    notes: "Note di Interesse",
    attachment: "Allegato",
    sendMyEmail: "Inoltra i dati inseriti al mio indirizzo E-mail",
    readPrivacyDisclaimer: "È necessario aprire il presente link affinché la casella diventi selezionabile. Ho letto l'informativa dei dati personali e presto il consenso.",
    sendCurriculum: "Invia Curriculum",
    productDetail: "Produkteinzelheiten",
    availabilityTravel: "Disponibilità agli spostamenti",
    code: "Artikel-Nr.",
    diameters: "Durchmesser",
    bolts: "Schrauben mit muttern",
    packs: "Vpe",
    dimension: "Abmessungen",
    article: "Artikel",
    search: "Suche",
    reset: "Zurücksetzen",
    viewDetails: "Einzelheiten",
    productNews: "Neuigkeiten",
    diameter: "Durchmesser",
    password: "Kennwort",
    passwordConfirm: "Kennwort wiederholen",
    emailNotSame: "E-Mail Adressen stimmen nicht überein",
    passwordNotSame: "Die eingetragen Passwörter stimmen nicht überein",
    signIn: "Senden",
    of: "von",
    whatSearch: "Was suchen Sie?",
    serenaManuelSpa: "Serena Manuel S.p.A. a socio unico",
    vat: "Ust-IdNr. und Steuernummer",
    info: "Informationen",
    generalConditions: "Allgemeine geschäftsbedingungen",
    legalNotes: "Impressum",
    technicalNotes: "Technische hinweise",
    personalDataInformation: "Informationsblatt zur Weiterverarbeitung Ihrer personenbezogenen Daten",
    cookiePolicy: "Cookie-Richtlinie",
    signUp: "Anmeldung",
    sendRequest: "Anfrage senden",
    personalArea: "Senden",
    login: "Anmelden",
    logout: "Logout",
    signup: "Anmeldung",
    editProfile: "Profil bearbeiten",
    forgotPassword: "Kennwort vergessen?",
    signUpTitle: "Anmeldung",
    legalNotesText: "Ich habe die rechtlichen Hinweise und allgemeine Datenschutzbedingungen gelesen und möchte mich anmelden.<br/><u>(Es ist notwendig, den Link zu öffnen, um der Feld zu aktivieren.)</u>",
    termsUseText: "Ich akzeptiere die Nutzungsbedingungen für technische Informationen.<br/><u>(Es ist notwendig, den Link zu öffnen, um der Feld zu aktivieren.)</u>",
    onRequest: "auf Anfrage",
    serenaManuelDescription: "Serena Manuel - Attrezzature Zootecniche",
    serenaManuelKeywords: "Serena Manuel, Luzzara, Attrezzature Zootecniche, Schellen",
    compile: "Bitte füllen Sie die unterstehenden Felder aus, um den Registriereintrag in unseren Kundenbereich anzufordern.<br>Wir werden Sie sobald wie möglich kontaktieren.",
    dataForSignup: "Login-daten",
    dataForForgotPassword: "Passwortwiederherstellungsdaten",
    send: "Senden",
    country: "Land",
    fax: "Fax Nr.",
    repeatEmail: "E-Mail-Adresse wiederholen",
    note: "Anmerkungen",
    requiredFields: "* Pflichtfeld",
    noSearch: "Keine Artikel gefunden",
    productsCategories: "Produktkategorien",
    curriculumPdf: "In aggiunta a quanto stabilito nell’ informativa generale precisiamo che i dati forniti sono trattati esclusivamente ai fini della valutazione della candidatura e che saranno custoditi come per legge per poter contattare il candidato anche in futuro, ove si rendessero disponibili opportunità di lavoro di suo interesse.",
    linkContacts: "<strong>NB:</strong> Übermittelte personenbezogenen Daten werden laut dem über diesen Link erreichbaren Informationsblatt weiterverarbeitet.",
    filters: "Suche",
    placeholderSearchBy: "Website durchsuchen...",
    fromRange: "Von",
    toRange: "Bis",
    fillRequiredFields: "Füllen Sie die erforderlichen Felder ",
    curriculumSent: "Curriculum inviato con successo",
    curriculumError: "Errore durante l'invio",
    attention: "Achtung",
    errorComponentPdf: " Achtung bitte: um den \" technischen Tisch\" zu downloaden,müssen Sie sich registrieren",
    downloadFile: "Downloaden",
    getReservedCatalog: "Reservierter katalog",
    sendResetPasswordSuccess: "Wir haben Ihnen eine E-mail gesandt, bitte die Nachrichte prüfen",
    sendResetPasswordError: "Fehler beim Senden",
    lastUpdate: "Neuester Stand:",
    noProducts: "Keinen Artikel gefunden",
    searchBy: "Suche nach",
    perPage: "zu Seite",
    streetOrSquare: "Via o piazza",
    category: "Kategorie",
    material: "Material",
    description: "Beschreibung",
    hoursFromMondayToFriday: "8:00 - 12:00 und 14:00 - 18:00",
    addressSerenaManuel: "Via Parri 28 - 42045, Luzzara (RE)",
    addressSerenaManuelFooter: "Via F. Parri, 28<br/>42045 Luzzara (RE) Italien",
    fieldRequired: "Pflichtfeld",
    emailNotValid: "Ungültige E-Mail-Adresse",
    minPassword: " Passwort muss mindesten 8 Zeichen",
    rulesPassword: "Das Passwort muss mind. 2 Kleinbuchstaben ,2 Großbuchstaben,2 Zahlen und 2 Sonderzeichen beinhalten",
    successAlert: "ERFOLGREICH",
    warningAlert: "Achtung",
    errorAlert: "FEHLER",
}