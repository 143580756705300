import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./lang"
// import store from "./store/store";
import {
    languages
} from "./store/data"


Vue.use(VueI18n);

const lang = JSON.parse(localStorage.getItem("selectedLanguage")) || languages[0]

const i18n = new VueI18n({
  locale: lang.locale,
  messages
});

export default i18n;