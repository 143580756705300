<template>
  <div class="row">
    <p class="title-section">{{ $t("message.editProfile") }}</p>
    <div class="col-12 px-2">
      <panel title="Dettaglio Profilo">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Azienda</label>
              <input type="text" v-model="extra.company" class="form-control" placeholder="Azienda">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>E-mail</label>
              <input type="text" v-model="email" class="form-control" placeholder="E-mail">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Password</label>
              <input type="password" v-model="password" class="form-control" placeholder="Password">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Nazione</label>
              <input type="text" v-model="extra.country" class="form-control" placeholder="Nazione">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Indirizzo</label>
              <input type="text" v-model="extra.address" class="form-control" placeholder="Indirizzo">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Città</label>
              <input type="text" v-model="extra.city" class="form-control" placeholder="Città">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>CAP</label>
              <input type="text" v-model="extra.postalCode" class="form-control" placeholder="CAP">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Telefono</label>
              <input type="text" v-model="extra.telephone" class="form-control" placeholder="Telefono">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Fax</label>
              <input type="text" v-model="extra.fax" class="form-control" placeholder="Fax">
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Note</label>
              <textarea rows="10" type="text" v-model="extra.note" class="form-control" placeholder="Note"></textarea>
            </div>
          </div>
          <div class="col-12">
            <a class="btn btn-primary" @click="save">Salva</a>
          </div>
        </div>
      </panel>
    </div>
  </div>
</template>

<script>
// import UserService from '../api/UserService'
import loader from '../config/loader'
// import swal from '../config/swal'
// import utils from '../utils/utils'
import {mapGetters} from 'vuex'
import utils from '../utils/utils'
import LoginService from '../api/LoginService'
import notification from '../config/notification'

export default {
  metaInfo() {
    return {
      title: this.$t("message.editProfile"),
      titleTemplate: 'Serena Manuel | %s'
    }
  },
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isSaved: false,
      oldData: "",
      active: true,
      extra: {}
    }
  },
  async mounted() {
    loader.showLoader()
    // this.firstName = this.user.first_name
    // this.lastName = this.user.last_name
    // this.email = this.user.email
    await LoginService.getAccount()
        .then(resp => {
          // this.firstName = resp.data.first_name
          // this.lastName = resp.data.last_name
          this.email = resp.data.email
          this.extra = resp.data.extra
          // this.oldData = utils.getJsonData(this.$data)
          // this.isSaved = true
          // this.backRoute()
        })
        .catch(err => {
          notification.notifyError("Errore durante la visualizzazione")
          console.log(err)
        })
    loader.hideLoader()
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    // backRoute(){
    //     this.$router.push({name: 'Users'})
    // },
    async save() {
      loader.showLoader()
      let params = {
        // first_name: this.firstName,
        // last_name: this.lastName,
        email: this.email,
        username: this.email,
        extra: this.extra
      }
      if (!utils.checkIsUndefinedOrEmpty(this.password)) {
        params["password"] = this.password
      }
      await LoginService.changeAccount(params)
          .then(resp => {
            notification.notifySuccess("Profilo aggiornato")
            this.$store.commit('setUser', resp.data)
            // this.oldData = utils.getJsonData(this.$data)
            // this.isSaved = true
            // this.backRoute()
          })
          .catch(err => {
            notification.notifyError("Errore durante l'aggiornamento")
            console.log(err)
          })
      loader.hideLoader()
    },
  },
  // beforeRouteLeave(to, from, next) {
  //     if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
  //         next()
  //         return
  //     }
  //     swal.alertLeave(next)
  // }
}
</script>