<template>
    <div class="input-group mr-3 flex-nowrap">
        <vue-select class="flex-1" label="description" :filterable="false" :options="options" v-model="product"
            :clearSearchOnBlur="clearSearchOnBlur" :clearSearchOnSelect="true"
            :placeholder="$t('message.placeholderSearchBy')" @open="() => product = null" @search="onSearch"
            @input="goToDetail">
            <template slot="no-options">
                <span v-if="utils.checkObjectIsUndefinedOrEmpty(product)">{{ $t('message.placeholderSearchBy') }}</span>
                <span v-else>{{ $t("message.noSearch") }}</span>
            </template>
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes"></span>
            </template>
            <template slot="option" slot-scope="option">
                <!-- <div class="d-center row w-100" @click="goToDetail(option)"> -->
                <!-- <div style="width: 100px; height: 100px;"> -->
                <img height="100" :src="option.thumbnail" />
                <!-- </div> -->
                <span class="long-name-search">{{ option.description[selectedLanguage.locale] }}</span>

                <!-- <div class="col-2 custom-card img-div-search">
										<img width="100%" :src='option.thumbnail' />
									</div>
									<div class="style-search col-10">
										<span style="display: inline-block; vertical-align: middle;" v-html="option.description[selectedLanguage.locale]">
										</span>
									</div> -->
                <!-- </div> -->
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected d-center" v-html="option.description[selectedLanguage.locale]">
                </div>
            </template>
        </vue-select>
        <div class="input-group-append flex-shrink-1">
            <button class="btn btn-outline-primary btn-primary-no-hover no-cursor" type="button">
                <img src="@/assets/icons/cerca_azzurro.svg" class="image-icon-20" alt=""></button>
        </div>
    </div>
</template>

<script>
import utils from '../../utils/utils.js'
import { mapGetters } from 'vuex'
import CatalogService from '../../api/CatalogService'

export default {
	name: 'Search',
	data() {
		return {
			utils: utils,
			options: [],
			product: null,
			timer: null
		}
	},
	computed: {
		...mapGetters(["selectedLanguage"]),
		params() {
			return {
				locale: this.selectedLanguage.locale
			}
		},
	},
	methods: {
		clearSearchOnBlur() {
			return true
		},
		goToDetail(element) {
			if(utils.checkObjectIsUndefinedOrEmpty(element)) {
				return
			}
			this.$router.push({name: "DetailProduct", params: {locale: this.selectedLanguage.locale, id: element.id}})
			this.product = null
		},
		async onSearch (search, loading) {
			loading(true)
			if(!utils.checkIsUndefinedOrEmpty(this.timer)){
				clearTimeout(this.timer)
			}
			this.timer = setTimeout(async () => {
				if(!utils.checkIsUndefinedOrEmpty(search)) {
					await CatalogService.search(search)
						.then(resp => {
							this.options = resp.data.articles
						})
						.catch(err => {
							console.log(err)
						})
				} else {
					this.options = []
				}
				loading(false)
			}, 1000)
			//  ... do some asynchronous stuff!  
		},
	}
}
</script>