<template>
    <panel :noHeader="true" :title="$t('message.notes', 'it')">
        <div class="row" v-for="(f, index) in file" :key="index">
            <label class="col-md-2 col-3 fw-normal">Allegato {{ index + 1 }}</label>
            <div class="col-md-10 col-9">
                <input :ref="'file' + index" @change="e => changeFile(e, index)" class="form-control" type="file" />
            </div>
        </div>
        <div class="row" v-if="file.length < 5">
            <div class="col-12">
                <b-button class="w-100" variant="primary" @click="addFile">
                    Aggiungi file
                </b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1 col-2 checkbox-start">
                <input v-model="send_to_user_email" class="form-check-input" type="checkbox" />
            </div>
            <label class="col-md-11 col-10 fw-normal">{{ $t('message.sendMyEmail', 'it') }}</label>
        </div>
        <div class="row">
            <div class="col-md-1 col-2 checkbox-start">
                <input v-model="privacy_consent" :disabled="!clicked" class="form-check-input" type="checkbox" />
            </div>
            <a @click="clickDownload" target="_blank" class="col-md-11 col-10 fw-normal click-download" v-html="readPrivacyDisclaimer"></a>
        </div>
        <div class="row">
            <div class="col-auto">
                <b-button :disabled="!privacy_consent" class="mt-3" variant="primary" @click="$emit('send')">
                    {{ $t("message.sendCurriculum", 'it') }}
                </b-button>
            </div>
            <div class="col-auto">
                <b-button class="mt-3 ms-3 btn-reset" variant="primary" @click="$emit('reset')">
                    {{ $t("message.reset", 'it') }}
                </b-button>
            </div>
        </div>
        <!-- <b-button class="mt-3" variant="primary" @click="$emit('send')">
            {{ $t("message.sendCurriculum", 'it') }}
        </b-button> -->
        
    </panel>
</template>

<script>
import { mapGetters } from 'vuex'
import constants from '../../config/constants'
import utils from '../../utils/utils'
import loader from "@/config/loader";
import DownloadService from "@/api/DownloadService";
function initialState() {
    return {
        clicked: false,
        privacy_consent: false,
        send_to_user_email: false,
        file: [null],
        utils,
    }
}

export default {
    data() {
        return initialState()
    },
    methods: {
        async clickDownload() {
            // var element = utils.findObject(this.downloads, constants.CONTACTS, "tag")
            const element = utils.findObjectDownload(this.downloads, constants.CURRICULUM, "tag");
            if (utils.checkObjectIsUndefinedOrEmpty(element)) {
                return
            }
            loader.showLoader()
            let data = "";
            await DownloadService.downloadDownload(element.id, 'it')
                .then(resp => {
                    data = resp
                })
                .catch(err => {
                    console.log(err)
                })
            utils.downloadFile(data)
            loader.hideLoader()
            this.clicked = true
        },
        changeFile(e, index) {
            var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                if(utils.checkIsUndefinedOrEmpty(index)) {
                    return
                }
                this.file[index] = files[0]
        },
        addFile() {
            this.file.push(null)
        },
        reset() {
            Object.assign(this.$data, initialState());
        }
    },
    computed: {
        ...mapGetters(["downloads", "selectedLanguage", "translations"]),
        link() {
            var element = utils.findObjectDownload(this.downloads, constants.CURRICULUM, "tag")
            if(utils.checkObjectIsUndefinedOrEmpty(element)) {
                return null
            }
            if(utils.checkObjectIsUndefinedOrEmpty(element.files)) {
                return null
            }
            if(utils.checkObjectIsUndefinedOrEmpty(element.files['it'].file)) {
                return null
            }
            return element.files['it'].file
        },
        readPrivacyDisclaimer() {
            if(this.translations.length === 0){
                return this.$t("message.readPrivacyDisclaimer", "it")
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.READ_PRIVACY_DISCLAIMER.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){
                return this.$t("message.readPrivacyDisclaimer", "it")
            }
            return object.value["it"]
        },
    }
}
</script>

<style>
.click-download > * {
  margin-bottom: 0;
}
</style>