export const languages = [{
    locale: "it",
    lang: "italian",
    flag: "it"
},
{
    locale: "en",
    lang: "english",
    flag: "en"
},
{
    locale: "fr",
    lang: "french",
    flag: "fr"
},
{
    locale: "de",
    lang: "german",
    flag: "de"
},
{
    locale: "es",
    lang: "spanish",
    flag: "es"
}]