import Swal from "sweetalert2";
import i18n from "@/i18n";

export default {
    withConfirm,
    alertLeave,
    error,
    success,
    successIt,
    errorIt,
    errorWithTitle
}

function withConfirm(title, text, successText, callback){
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: "No"
    }).then((result) => {
        if (result.isConfirmed) {
            callback()
            Swal.fire({
                confirmButtonColor: '#3085d6',
                title: 'Confermato!',
                text: successText,
                icon: 'success'
            })
        }
    })
}

function alertLeave(next) {
    Swal.fire({
        title: 'Sei sicuro di abbandonare?',
        text: "Perderai tutte le modifiche effettuate",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
    }).then((result) => {
    if (result.value) {
        next()
    }
    })
}

function success(message) {
    Swal.fire(i18n.t("message.successAlert"), message, "success")
}

function error(message) {
    Swal.fire(i18n.t("message.errorAlert"), message, "error")
}

function successIt(message) {
    Swal.fire(i18n.t("message.successAlert", "it"), message, "success")
}

function errorIt(message) {
    Swal.fire(i18n.t("message.errorAlert", "it"), message, "error")
}

function errorWithTitle(title, message) {
    Swal.fire(title, message, "error")
}