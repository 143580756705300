import { render, staticRenderFns } from "./Knowledges.vue?vue&type=template&id=45c2e288&"
import script from "./Knowledges.vue?vue&type=script&lang=js&"
export * from "./Knowledges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports