import Api from './index.js'
import store from '../store/store.js'

export default {
    newLog(action, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/audit/log/${action}`, params, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
    },
}