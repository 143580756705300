import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchConfigs(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/configs/?format=datatables' + extra)
    },
    getConfig(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/configs/${id}/`)
    },
    // changeConfig(id, params) {
    //     var token = store.state.token
    //     Api().setAuthHeaderWithToken(token)
    //     return Api().patch(`api/admin/configs/${id}/`, params)
    // },
    // newConfig(params) {
    //     var token = store.state.token
    //     Api().setAuthHeaderWithToken(token)
    //     return Api().post(`api/admin/configs/`, params)
    // },
    // deleteConfig(id) {
    //     var token = store.state.token
    //     Api().setAuthHeaderWithToken(token)
    //     return Api().delete(`api/admin/configs/${id}/`)
    // }
}
