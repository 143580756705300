import Vue from 'vue'
import Vuex from 'vuex'
import router from '../config/AppRoutes.vue'
import LoginService from '../api/LoginService.js'
import axios from 'axios'
import notification from '../config/notification.js'
import utils from '../utils/utils'
// import VueI18n from 'vue-i18n'
// import messages from "../lang"
import i18n from '../i18n'

import {
    languages
} from "./data"

Vue.use(Vuex);
// Vue.use(VueI18n)


const store = new Vuex.Store({
    state: {
        user: JSON.parse(localStorage.getItem('user')) || '',
        languages,
        selectedLanguage: JSON.parse(localStorage.getItem("selectedLanguage")) || languages[0],
        filters: JSON.parse(localStorage.getItem("filters")) || null,
        loader: false,
        token: localStorage.getItem('token') || '',
        refresh: localStorage.getItem('refresh') || '',
        news: [],
        downloads: [],
        translations: [],
        configs: [],
        curriculum: JSON.parse(localStorage.getItem("curriculum")) || null,
        codeComponent: '',
        yProducts: parseInt(sessionStorage.getItem("yProducts")) || 0,
        isList: JSON.parse(sessionStorage.getItem("isList")) || false
    },
    getters: {
        user: state => {
            return state.user
        },
        languages: state => {
            return state.languages
        },
        selectedLanguage: state => {
            return state.selectedLanguage
        },
        filters: state => {
            return state.filters
        },
        loader: state => {
            return state.loader
        },
        isLogged: state => {
            return !utils.checkObjectIsUndefinedOrEmpty(state.user)
        },
        news: state => {
            return state.news
        },
        downloads: state => {
            return state.downloads
        },
        translations: state => {
            return state.translations
        },
        configs: state => {
            return state.configs
        },
        curriculum: state => {
            return state.curriculum
        },
        codeComponent: state => {
            return state.codeComponent
        },
        yProducts: state => {
            return state.yProducts
        },
        isList: state => {
            return state.isList
        }
    },
    actions: {
        async signinUser(context, payload) {
            const { user } = payload;
            context.commit('loginUser');
            await LoginService.authenticate(user)
                .then(async resp => {
                    context.commit('setUser', resp.data.user);
                    context.commit('loginUserSuccess', resp.data.access);
                    context.commit('setRefresh', resp.data.refresh);
                    context.commit('resetFilters')
                })
                .catch(error => {
                    var err = error.response
                    console.log(err)
                    if(Array.isArray(Object.values(err.data)[0])) {
                        notification.notifyError(Object.values(err.data)[0][0])
                    } else {
                        notification.notifyError(Object.values(err.data)[0])
                    }
                    
                    if(err.status === 400){
                        context.commit('loginUserFailure', 'Credenziali Errate');
                    } else {
                        context.commit('loginUserFailure', error.message);
                    }
                });
        },
        logout(context) {
            context.commit('logoutUser')
            context.commit('resetFilters')
        }
    },
    mutations: {
        setIsList(state, isList) {
            state.isList = isList
            sessionStorage.setItem("isList", isList)
        },
        setYProducts(state, y) {
            state.yProducts = y
            sessionStorage.setItem("yProducts", y)
        },
        setCodeComponent(state, codeComponent){
            state.codeComponent = codeComponent
        },
        setCurriculum(state, curriculum){
            state.curriculum = curriculum
            localStorage.setItem("curriculum", JSON.stringify(curriculum))
        },
        removeCurriculum(state){
            state.curriculum = null
            localStorage.removeItem("curriculum")
        },
        setTranslations(state, translations){
            state.translations = translations
        },
        setConfigs(state, configs){
            state.configs = configs
        },
        setDownloads(state, downloads){
            state.downloads = downloads
        },
        pushDownloads(state, downloads){
            state.downloads = state.downloads.concat(downloads)
        },
        setNews(state, news) {
            state.news = news
        },
        loginUser(state) {
            state.loader = true
        },
        setUser(state, user){
            state.user = user
            localStorage.setItem('user',JSON.stringify(user));
        },
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token);
        },
        setRefresh(state, refresh) {
            state.refresh = refresh
            localStorage.setItem('refresh', refresh);
        },
        loginUserSuccess(state, token) {
            state.token = token
            localStorage.setItem('token', token);
            setTimeout(function(){
                router.push(router.history.current.query.redirect || {name: 'Home', params: {locale: state.selectedLanguage.locale}})
                router.go()
                // Vue.notify({
                //     group: 'message',
                //     type: 'success',
                //     text: 'Login avvenuto con successo!'
                // });
                state.loader = false
           },1500);
        },
        loginUserFailure(state, error) {
            console.log(error)
            state.loader = false
            // Vue.notify({
            //     group: 'message',
            //     type: 'error',
            //     text: error
            // });
        },
        logoutUser(state) {
            delete axios.defaults.headers.common['Authorization']
            state.token = ''
            localStorage.removeItem('user');
            state.user = null
            localStorage.removeItem('token');
            state.downloads = []
            router.push({name: 'Home', params: {locale: state.selectedLanguage.locale}})
                .catch(() => {
                    router.go()
                })
        },
        logoutUserWithoutLogin(state) {
            delete axios.defaults.headers.common['Authorization']
            state.token = ''
            localStorage.removeItem('user');
            state.user = null
            localStorage.removeItem('token');
        },
        setLoader(state, value) {
            state.loader = value
        },
        changeLanguage(state, language) {
            i18n.locale = language.locale
            state.selectedLanguage = language
            localStorage.setItem("selectedLanguage", JSON.stringify(language))
        },
        saveFilters(state, filters) {
            state.filters = filters
            localStorage.setItem("filters", JSON.stringify(filters))
        },
        resetFilters(state) {
            state.filters = null
            localStorage.removeItem("filters")
        }
    }
})

// const i18n = new VueI18n({
// 	locale: store.state.selectedLanguage.locale,
// 	messages, // set locale messages
// })
export default store
