<template>
    <panel :title="$t('message.personalData', 'it')">
        <div class="row">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.firstName', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="first_name" name="first_name" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('first_name') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.lastName', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="last_name" name="last_name" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('last_name') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.birthPlace', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="birth_city" name="birth_city" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('birth_city') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.birthProvince', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="birth_province" name="birth_province" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('birth_province') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.birthCountry', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="birth_country" name="birth_country" v-validate="'required'" maxlength="50" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('birth_country') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.birthDate', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <datepicker :language="$data[selectedLanguage.locale]" v-model="birth_date" :disabled-dates="disable_dates" name="birth_date" v-validate="'required'" input-class="form-control bg-white"></datepicker>
                <span class="error-span mt-3 d-block">{{ errors.first('birth_date') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.placeResidence', 'it') }} <span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="residence_city" name="residence_city" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('residence_city') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.postalCode', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="residence_postal_code" name="residence_postal_code" v-validate="'required'" maxlength="10" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('residence_postal_code') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.province', 'it') }} <span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="residence_province" name="residence_province" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('residence_province') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.streetOrSquare', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="residence_address" name="residence_address" v-validate="'required'" maxlength="150" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('residence_address') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.houseNumber', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="residence_address_number" name="residence_address_number" v-validate="'required'" maxlength="10" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('residence_address_number') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.nationality', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="residence_country" name="residence_country" v-validate="'required'" maxlength="50" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('residence_country') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.telephone', 'it') }} <span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="phone_number" name="phone_number" v-validate="'required'" maxlength="50" type="text" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('phone_number') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label align-items-start fw-normal">{{ $t('message.email', 'it') }}<span class="color-red">*</span></label>
            <div class="col-md-8 flex-column align-items-start">
                <input v-model="email" name="email" v-validate="'required'" type="email" class="form-control" />
                <span class="error-span mt-3 d-block">{{ errors.first('email') }}</span>
            </div>
        </div>
        <div class="row mt-3">
            <p class="color-red">{{ $t("message.requiredFields", 'it') }}</p>
        </div>
    </panel>
</template>

<script>
import { mapGetters } from 'vuex'
import {en, es, fr, it, de} from 'vuejs-datepicker/dist/locale'

function initialState() {
    return {
        en,
        es,
        fr,
        it,
        de,
        first_name: "",
        last_name: "",
        birth_city: "",
        birth_province: "",
        birth_country: "",
        birth_date: "",
        residence_city: "",
        residence_postal_code: "",
        residence_province: "",
        residence_address: "",
        residence_address_number: "",
        residence_country: "",
        phone_number: "",
        email: "",
        disable_dates: {
            from: new Date()
        }
    }
}

export default {
    data() {
        return initialState()
    },
    computed: {
        ...mapGetters(['selectedLanguage'])
    },
    mounted() {
        // const dictionary = {
        //     en: {
        //         // attributes and messages
        //     },
        //     it: {
        //         messages:{
        //             required: 'Campo Richiesto'
        //         }
        //     }
        // };
        // this.$validator.localize(dictionary);
        // this.$validator.localize('it');
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>