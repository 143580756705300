<script>
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from '../pages/Home.vue'
// import Products from '../pages/Products.vue'
import ProductsAll from '../pages/ProductsAll.vue'
import DetailProduct from '../pages/DetailProduct.vue'
import DetailNews from '../pages/DetailNews.vue'
import Download from '../pages/Download.vue'
import News from '../pages/News.vue'
import Company from '../pages/Company.vue'
import Contacts from '../pages/Contacts.vue'
import Curriculum from '../pages/Curriculum.vue'
import Login from '../pages/Login.vue'
import Profile from '../pages/Profile.vue'
import CustomerArea from '../pages/CustomerArea.vue'
import Signup from '../pages/Signup.vue'
import ForgotPassword from '../pages/ForgotPassword.vue'
import VerifyRegistration from '../pages/VerifyRegistration.vue'
import ResetPassword from '../pages/ResetPassword.vue'
import store from '../store/store'
import utils from '../utils/utils'

Vue.use(Router)
Vue.use(Meta)


// function findBasePath() {
//   // eslint-disable-next-line
//   const locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');

//   var basePath = undefined;
//   const cleanLocale = locale.trim().toLowerCase();
//   if (cleanLocale.length == 2 && locale != "/" && !utils.checkObjectIsUndefinedOrEmpty(utils.findObject(store.state.languages, cleanLocale, "locale"))) {
//     basePath = '/' + cleanLocale;
//   }

//   return basePath;
// }

var routes = [
  {
    path: '/verify-registration/',
    component: VerifyRegistration,
    name: 'VerifyRegistration'
  },
  {
    path: '/reset-password/',
    component: ResetPassword,
    name: 'ResetPassword'
  },
  {
    path: '/:locale(it|de|fr|en|es)?',
    component: {
      render: h => h('router-view')
    },
    children: [
      {
        path: '',
        component: Home,
        name: 'Home',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'company',
        component: Company,
        name: 'Company',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'products',
        // component: Products,
        component: ProductsAll,
        name: 'Products',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'products-all',
        component: ProductsAll,
        name: 'ProductsAll',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'products/:id',
        component: DetailProduct,
        name: 'DetailProduct',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'download',
        component: Download,
        name: 'Download',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'news',
        component: News,
        name: 'News',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'news/:id',
        component: DetailNews,
        name: 'DetailNews',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'contacts',
        component: Contacts,
        name: 'Contacts',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'curriculum',
        component: Curriculum,
        name: 'Curriculum',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'customer-area/login',
        component: Login,
        name: 'CustomerAreaLogin',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'customer-area/signup',
        component: Signup,
        name: 'Signup',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'customer-area/forgot-password',
        component: ForgotPassword,
        name: 'ForgotPassword',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'logout',
        component: Login,
        name: "Logout"
      },
      {
        path: 'customer-area',
        component: CustomerArea,
        name: 'CustomerArea',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'profile',
        component: Profile,
        name: 'Profile',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '*',
        redirect: '/',
        name: 'NotFound'
      }
    ]
  },
  {
    path: '/(.*)',
    redirect: '/'
  }
];

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: routes
})

router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  // console.log(to)
  if (to.name === 'VerifyRegistration' || to.name === 'ResetPassword') {
    return next()
  }
  if (utils.checkIsUndefined(to.params.locale)) {
    to.params.locale = store.state.selectedLanguage.locale
    var newParams = to.params
    newParams["locale"] = store.state.selectedLanguage.locale
    return next({name: to.name, params: newParams, query: to.query})
  }
  var selectedLanguage = utils.findObject(store.state.languages, to.params.locale, "locale")
  if (selectedLanguage.locale === store.state.selectedLanguage.locale) {
    return next()
  }
  store.commit("changeLanguage", selectedLanguage)
  return next();

});

export default router;
</script>
