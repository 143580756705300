<template>
    <div class="container px-2">
        <p class="title-section">{{ $t("message.customerArea") }}</p>
        <div class="row w-100">
            <div class="col-12 col-md-5 ps-0 pt-md-0 pe-0 pe-md-2">
                <div class="p-4 pb-5 custom-card h-100 d-flex flex-column ">
                    <p class="title-section">{{ $t("message.login") }}</p>
                    <form>
                        <div class="form-group mb-3">
                            <label for="exampleInputEmail1">E-mail</label>
                            <input type="email" v-model="email" class="form-control" id="exampleInputEmail1"
                                   aria-describedby="emailHelp" placeholder="E-mail">
                        </div>
                        <div class="form-inline">
                            <div>
                                <label class="w-100">
                                    <span class="strong-label">Password</span>
                                    <input @keyup.enter="submit" v-model="password"
                                           :type="visiblePassword ? 'text' : 'password'"
                                           class=" input-eye password-field form-control d-inline"/>
                                    <img @click="visiblePassword = !visiblePassword"
                                         :src="require(`@/assets/icons/psw_${visiblePassword ? 'nascondi' : 'vedi'}.svg`)"
                                         class="image-icon-24" alt="">
                                    <!-- <span @click="visiblePassword = !visiblePassword" :class="`display-eye fa fa-eye${visiblePassword ? '-slash' : ''}`"></span> -->
                                </label>
                            </div>
                        </div>
                    </form>
                    <div class="login-buttons mt-md-auto mt-3">
                        <button @click="submit" class="btn min-width-88 h-45px mr-3 btn-primary d-inline btn-lg">
                            {{ $t("message.signIn") }}
                        </button>
                        <a @click="forgotPassword" class="color-main text-decoration-none cursor-pointer">
                            {{ $t("message.forgotPassword") }}</a>
                    </div>
                </div>
            </div>
            <div class="col-md-7 pe-0 ps-0 ps-md-2 pt-md-0 d-flex flex-column ">
                <div class="p-4 pb-5 custom-card h-100">
                    <p class="title-section">{{ $t("message.signUpTitle") }}</p>
                    <div class="form-check mb-3">
                        <input v-model="checkLegalNotes" class="form-check-input border-radius-0"
                               :disabled="readLegalNotes" type="checkbox">
                        <label @click="clickLegalNotes" class="cursor-pointer" for="flexCheckDefault"
                               v-html="$t('message.legalNotesText')">
                        </label>
                    </div>
                    <div class="form-check mb-4">
                        <input v-model="checkTermsUse" class="form-check-input border-radius-0" :disabled="readTermsUse"
                               type="checkbox">
                        <label @click="clickTermsUse" class="cursor-pointer" for="flexCheckDefault"
                               v-html="$t('message.termsUseText')">
                        </label>
                    </div>
                    <div class="login-buttons mt-md-auto mt-3">
                        <button :disabled="!checkLegalNotes || !checkTermsUse" @click="signup"
                                class="btn h-45px mr-3 btn-primary d-inline btn-lg min-width-88">
                            {{ $t("message.signUp") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import constants from '../config/constants'
import utils from '../utils/utils'
import {mapGetters} from 'vuex'
import notification from '../config/notification'
import LoginService from '../api/LoginService'
import DownloadService from "@/api/DownloadService";
import loader from "@/config/loader";

export default {
    metaInfo() {
        return {
            title: this.$t("message.login"),
            titleTemplate: constants.TITLE_TEMPLATE
        }
    },
    data() {
        return {
            email: "",
            password: "",
            visiblePassword: false,
            readLegalNotes: true,
            readTermsUse: true,
            checkTermsUse: false,
            checkLegalNotes: false
        }
    },
    computed: {
        ...mapGetters(["downloads", "selectedLanguage", "translations"]),
        registerError() {
            if (this.translations.length === 0) {
                return "Errore durante il login, riprova"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.REGISTER_ERROR.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if (utils.checkObjectIsUndefinedOrEmpty(object)) {
                return "Errore durante il login, riprova"
            }
            return object.value[this.selectedLanguage.locale]
        },
    },
    methods: {
        forgotPassword() {
            this.$router.push({name: 'ForgotPassword', params: {locale: this.selectedLanguage.locale}})
        },
        signup() {
            this.$router.push({name: 'Signup', params: {locale: this.selectedLanguage.locale}})
        },
        async clickLegalNotes() {
            const element = utils.findObjectDownload(this.downloads, constants.REGISTRAZIONE1, "tag");
            if (utils.checkObjectIsUndefinedOrEmpty(element)) {
                return
            }
            loader.showLoader()
            let data = "";
            await DownloadService.downloadDownload(element.id, this.selectedLanguage.locale)
                .then(resp => {
                    data = resp
                })
                .catch(err => {
                    console.log(err)
                })
            utils.downloadFile(data)
            loader.hideLoader()
            this.readLegalNotes = false
        },
        async clickTermsUse() {
            const element = utils.findObjectDownload(this.downloads, constants.REGISTRAZIONE2, "tag");
            if (utils.checkObjectIsUndefinedOrEmpty(element)) {
                return
            }
            loader.showLoader()
            let data = "";
            await DownloadService.downloadDownload(element.id, this.selectedLanguage.locale)
                .then(resp => {
                    data = resp
                })
                .catch(err => {
                    console.log(err)
                })
            utils.downloadFile(data)
            loader.hideLoader()
            this.readTermsUse = false
        },
        getError(error) {
            if (this.translations.length === 0) {
                return this.registerError
            }
            let key = error.code
            if (error.attr === 'email') {
                key = `${error.attr}_${error.code}`
            }
            var object = this.translations.find(e => e.key.toUpperCase() === key.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if (utils.checkObjectIsUndefinedOrEmpty(object)) {
                return this.registerError
            }
            return object.value[this.selectedLanguage.locale]
        },
        async submit() {
            const user = {
                username: this.email,
                password: this.password
            };
            this.$store.commit('loginUser');
            await LoginService.authenticate(user)
                .then(async resp => {
                    this.$store.commit('setUser', resp.data.user);
                    this.$store.commit('loginUserSuccess', resp.data.access);
                    this.$store.commit('setRefresh', resp.data.refresh);
                    this.$store.commit('resetFilters')
                })
                .catch(err => {
                    if (utils.checkArrayIsUndefinedOrEmpty(err.response.data.errors)) {
                        notification.notifyError(this.registerError)
                        return
                    }
                    if (utils.checkObjectIsUndefinedOrEmpty(err.response.data.errors[0])) {
                        notification.notifyError(this.registerError)
                        return
                    }
                    let error = err.response.data.errors[0]
                    notification.notifyError(this.getError(error))
                    // var err = error.response
                    // console.log(err)
                    // if(Array.isArray(Object.values(err.data)[0])) {
                    //     notification.notifyError(Object.values(err.data)[0][0])
                    // } else {
                    //     notification.notifyError(Object.values(err.data)[0])
                    // }

                    if (err.status === 400) {
                        this.$store.commit('loginUserFailure', 'Credenziali Errate');
                    } else {
                        this.$store.commit('loginUserFailure', error.message);
                    }
                });
            // this.$store.dispatch("signinUser", {
            // 	user
            // });
        }
    }
}
</script>

<style>

</style>