<template>
    <b-nav-item-dropdown class="navbar-item" toggle-class="navbar-link dropdown-toggle pe-0" no-caret>
        <template slot="button-content">
            <img :src="require(`@/assets/icons/bandiera_${selectedLanguage.flag}.svg`)" class="image-icon-24" alt="">
            <!-- <span :class="'flag-icon me-1 flag-icon-' + selectedLanguage.flag"></span> -->
            <!-- <span class="name d-none d-sm-inline me-1 uppercase">{{ selectedLanguage.locale }}</span> <b class="caret"></b> -->
        </template>
        <b-dropdown-item @click="changeLanguage(lang)" v-for="lang in languages" :key="lang.locale">
            <img :src="require(`@/assets/icons/bandiera_${lang.flag}.svg`)" class="image-icon-24 me-2" alt=""> {{ $t('message.' + lang.lang) }}
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
		...mapGetters([
			"languages",
			"selectedLanguage"
		])
	},
	methods: {
        getImage(){
            return `@/assets/icons/bandiera_it.svg`
        },
		changeLanguage(language) {
            this.$i18n.locale = language.locale
			this.$store.commit("changeLanguage", language)
            this.$router.push({name: this.$route.name, params: {locale: language.locale}})
		}
    }
}
</script>