<template>
	<div>
		<carousel />
		<categories class="p-2" />
		<news />
		<card />
	</div>
</template>

<script>
import constants from '../config/constants'
export default {
    metaInfo() {
        return {
            title: this.$t("message.home"),
            titleTemplate: constants.TITLE_TEMPLATE,
            meta: [
                {vmid: "description", name: "description", content: "Homepage of SerenaManuel"}
            ]
        }
    },
}
</script>