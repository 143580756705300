import Api from './index.js'
import store from '../store/store.js'
import utils from "@/utils/utils";

export default {
    fetchArticles(extra = "") {
        return Api().get('api/catalog/article/?format=datatables' + extra)
    },
    fetchArticlesComplete(extra = "") {
        return Api().get('api/catalog/article_complete/?format=datatables' + extra)
    },
    fetchArticlesReserved(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/catalog/article_reserved/?format=datatables' + extra)
    },
    getArticle(id) {
        return Api().get(`api/catalog/article/${id}/`)
    },
    fetchCategories(extra = "") {
        return Api().get('api/catalog/category/?format=datatables' + extra)
    },
    fetchCategoriesReserved(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/catalog/category_reserved/?format=datatables' + extra)
    },
    getCategory(id) {
        return Api().get(`api/catalog/category/${id}/`)
    },
    fetchMaterials(extra = "") {
        return Api().get('api/catalog/material/?format=datatables' + extra)
    },
    getMaterial(id) {
        return Api().get(`api/catalog/material/${id}/`)
    },
    getArticleComponent(id) {
        return Api().get(`api/catalog/article_components/${id}/`)
    },
    downloadArticle(id, lang) {
        var token = store.state.token
        if(!utils.checkIsUndefinedOrEmpty(token)) {
            Api().setAuthHeaderWithToken(token)
        }
        return Api().get(`api/catalog/download/article/${id}/${lang}/`, {
            responseType: 'blob'
          })
    },
    downloadComponent(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/catalog/download/component/${id}/`, {
            responseType: 'blob'
          })
    },
    search(query) {
        return Api().get(`api/catalog/search/${query}/`)
    }
}
