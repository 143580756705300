<template>
    <div class="container px-2">
        <p class="title-section">{{ $t("message.contacts") }}</p>
        <div class="row w-100">
            <div class="col-md-5 mb-0 mb-md-4 ps-0">
                <div class="row w-100">
                    <span class="icon text-24 col-1 p-0 color-white">
                        <!-- <div class="all-54 bg-main center-content"> -->
                            <img src="@/assets/icons/posizione.svg" class="image-icon-36" alt="">
                        <!-- </div> -->
                    </span>
                    <span class="text col-10 py-0 ps-3 align-self-center">
                        <p class="title-section mb-0 text-16">{{ $t("message.serenaManuelSpa") }}</p>
                        <p class="mb-0">{{ $t("message.addressSerenaManuel") }}</p>
                    </span>
                </div>
                <div class="row w-100">
                    <span class="icon text-24 col-1 p-0 color-white">
                        <!-- <div class="all-54 bg-main center-content"> -->
                            <img src="@/assets/icons/orario_lavoro.svg" class="image-icon-36" alt="">
                        <!-- </div> -->
                    </span>
                    <span class="text col-10 py-0 ps-3 align-self-center">
                        <p class="mb-0">{{ $t("message.fromMondayToFriday") }}</p>
                        <p class="mb-0">{{ $t("message.hoursFromMondayToFriday") }}</p>
                    </span>
                </div>
            </div>
            <div class="col-md-5 mb-4 ps-0">
                <div class="row w-100">
                    <span class="icon text-24 col-1 p-0 color-white">
                        <!-- <div class="all-54 bg-main center-content"> -->
                            <img src="@/assets/icons/telefono.svg" class="image-icon-36" alt="">
                        <!-- </div> -->
                    </span>
                    <span class="text col-10 py-0 ps-3 align-self-center">
                        <p class="mb-0">Tel:<a href="tel:+390522977457">+39 0522 977457</a></p>
                        <p class="mb-0">Fax:<a href="tel:+390522977107">+39 0522 977107</a></p>
                    </span>
                </div>
                <div class="row w-100">
                    <span class="icon text-24 col-1 p-0 color-white">
                        <!-- <div class="all-54 bg-main center-content"> -->
                            <img src="@/assets/icons/mail.svg" class="image-icon-36" alt="">
                        <!-- </div> -->
                    </span>
                    <span class="text col-10 py-0 ps-3 align-self-center">
                        <p class="mb-0"><a href="mailto:info@serenamanuel.com">info@serenamanuel.com</a></p>
                    </span>
                </div>
            </div>
        </div>
        <p class="mt-2" @click.stop.prevent="clickDownload" v-html="contactsString"></p>
        <div>
            <div class="widget-map-body custom-card my-3">
                <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22589.93745979684!2d10.695217439616897!3d44.948908829608925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x44a76acc37f037af!2sSerena%20Manuel%20S.p.A.!5e0!3m2!1sit!2sit!4v1646066542644!5m2!1sit!2sit"
                        width="100%" height="400" style="border:0;" class="d-block" allowfullscreen
                        loading="lazy"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import constants from '../config/constants'
import {mapGetters} from 'vuex'
import utils from '../utils/utils'
import loader from "@/config/loader";
import DownloadService from "@/api/DownloadService";

export default {
    metaInfo() {
        return {
            title: this.$t("message.contacts"),
            titleTemplate: constants.TITLE_TEMPLATE
        }
    },
    methods: {
        async clickDownload() {
            // var element = utils.findObject(this.downloads, constants.CONTACTS, "tag")
            const element = utils.findObjectDownload(this.downloads, constants.CONTACTS, "tag");
            if (utils.checkObjectIsUndefinedOrEmpty(element)) {
                return
            }
            loader.showLoader()
            let data = "";
            await DownloadService.downloadDownload(element.id, this.selectedLanguage.locale)
                .then(resp => {
                    data = resp
                })
                .catch(err => {
                    console.log(err)
                })
            utils.downloadFile(data)
            loader.hideLoader()
        },
    },
    computed: {
        ...mapGetters(['translations', 'selectedLanguage', 'downloads']),
        contactsString() {
            if (this.translations.length === 0) {
                return ""
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.CONTACTS.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CONTACTS, "key")
            if (utils.checkObjectIsUndefinedOrEmpty(object)) {
                return ""
            }
            return object.value[this.selectedLanguage.locale]
        }
    }
}
</script>