<template>
  <div></div>
</template>

<script>
import LoginService from '../api/LoginService'
import notification from '../config/notification'
export default {
    mounted() {
        const params = {
            user_id: this.$route.query.user_id,
            timestamp: this.$route.query.timestamp,
            signature: this.$route.query.signature
        }
        LoginService.verifyAccount(params)
            .then(() => {
                notification.notifySuccess("Email Verificata, effettua l'accesso")
                this.$router.push({name: 'CustomerAreaLogin'}).catch(() => {});
            })
            .catch(err => {
                console.log(err)
                notification.notifyError("Errore durante la verifica")
            })
    }
}
</script>

<style>

</style>