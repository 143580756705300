<template>
    <div class="row">
        <!-- <div class="col-2 col-md-1">
            <b-button @click="back" variant="warning w-auto rounded-circle mr-5"><i class="fa fa-arrow-left"></i></b-button>
        </div> -->
        <!-- <div class="col-10 col-md-11 center-content"> -->
          <p class="title-section">{{ $t("message.news") }}</p>
          <p class="title-section font-28 mb-4">{{ summary[selectedLanguage.locale] }}</p>
        <!-- </div> -->
        <div v-if="!utils.checkIsUndefinedOrEmpty(images[selectedLanguage.locale])">
            <img v-if="images[selectedLanguage.locale].length === 1" width="100%" :src="utils.getPublicFile(images[selectedLanguage.locale][0].image)" class="image-news custom-card">
            <swiper v-else class="swiper visible-pagination" :options="swiperOption">
                <swiper-slide v-for="(image, index) in images[selectedLanguage.locale]" :key="index">
                    <div class="swiper-zoom-container">
                        <img class="custom-card" :src="utils.getPublicFile(image.image)">
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>

            </swiper>
        </div>
        <!-- <h3 class="mt-4">{{ summary[selectedLanguage.locale] }}</h3> -->
        <div class="mt-4" v-html="text[selectedLanguage.locale]">
        </div>
        <div class="row w-100">

          <div v-for="att in attachments[selectedLanguage.locale]" :key="att.id" class="text-18 padding-12 col-12">
            <img @click="download(att.file)" src="@/assets/icons/download_pdf.svg" class="image-icon-36 me-3" alt="">
            <!-- <i @click="download(element.file)" class="fa fa-file-arrow-down text-24 mr-3 mb-3 cursor-pointer"></i> -->
            <span @click="download(att.file)" class="cursor-pointer">{{ att.description }}</span>
          </div>
          <!-- <div class="card col-3" v-for="att in attachments[selectedLanguage.locale]" :key="att.id" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">{{ att.description }}</h5>
              <a @click="download(att.file)" class="btn btn-primary">Download</a>
            </div>
          </div> -->
        </div>
    </div>
</template>

<script>
import utils from '../utils/utils.js'
import {
  Swiper,
  SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import NewsService from '../api/NewsService.js'
import {
  mapGetters
} from 'vuex'
import loader from '../config/loader.js'
import constants from '../config/constants.js'

export default {
  components: {
    Swiper,
    SwiperSlide
  },    
    metaInfo() {
        return {
            title: this.title[this.selectedLanguage.locale],
            titleTemplate: constants.TITLE_TEMPLATE
        }
    },
  data() {
    return {
      utils,
      title: {},
      summary: {},
      text: {},
      swiperOption: {
        zoom: false,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      index: 0,
      visible: false,
      images: [],
      attachments: []
    }
  },
  async mounted() {
    loader.showLoader()
    await NewsService.getNews(this.$route.params.id)
      .then(resp => {
        this.text = resp.data.text
        this.summary = resp.data.summary
        this.title = resp.data.title
        this.images = resp.data.images
        this.attachments = resp.data.attachments
      })
      .catch(err => {
        console.log(err)
      })
    loader.hideLoader()
  },
  computed: {
    ...mapGetters([
      "selectedLanguage"
    ])
  },
  methods: {
    download(file) {
      utils.download(utils.getPublicFile(file))
    },
    back() {
      this.$router.push({
        name: "News", params: {locale: this.selectedLanguage.locale}
      })
    }
  }
}
</script>