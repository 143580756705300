<template>
  <navs :title="$t('message.knowledgeForeignLanguages', 'it')" :navs="navs" :elements="languages">
    <template v-slot:content="content">
      <div class="form-group row mb-4" v-if=" content.selectedNav === 'other' ">
        <label class="col-sm-4 ps-0">{{ $t(`message.note`) }}</label>
        <div class="col-sm-8">
          <textarea class="form-control" v-model="lang_other_notes"/>
        </div>
      </div>
      <div v-else class="form-group row mb-4" v-for="(element, key) in languages[content.selectedNav]" :key="key">
        <label class="col-sm-4 ps-0">{{ $t(`message.${key}`, 'it') }}</label>
        <div class="col-sm-8">
          <select class="form-select" v-model="$data[`lang_${content.selectedNav}_${key}`]">
            <option v-for="(level) in optionsLevel" :key="level" :value="level">
              {{ level }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <p class="mb-1">{{ $t("message.levelsLanguage", 'it') }}</p>
        <p @click="clickDownload" class="text-decoration-underline cursor-pointer">
          {{ $t("message.commonEuropean", 'it') }}</p>
      </div>
    </template>
  </navs>
</template>

<script>
import constants from '../../config/constants'
import utils from '../../utils/utils'
import {mapGetters} from 'vuex'
import DownloadService from "@/api/DownloadService";
import loader from "@/config/loader";

function initialState() {
  return {
    navs: [
      //     {
      //     id: "it",
      //     title: "message.italian",
      //     flag: "it"
      // },
      {
        id: "en",
        title: "message.english",
        flag: "en"
      },
      {
        id: "fr",
        title: "message.french",
        flag: "fr"
      },
      {
        id: "de",
        title: "message.german",
        flag: "de"
      },
      {
        id: "es",
        title: "message.spanish",
        flag: "es"
      },
      {
        id: "other",
        title: "message.other"
      }],
    languages: {
      it: utils.levelLanguage(),
      en: utils.levelLanguage(),
      fr: utils.levelLanguage(),
      de: utils.levelLanguage(),
      es: utils.levelLanguage(),
      other: utils.levelLanguage()
    },
    other: "",
    lang_it_comprehension_listen: "",
    lang_it_comprehension_read: "",
    lang_it_verbal_interaction: "",
    lang_it_verbal_oral: "",
    lang_it_write: "",
    lang_en_comprehension_listen: "",
    lang_en_comprehension_read: "",
    lang_en_verbal_interaction: "",
    lang_en_verbal_oral: "",
    lang_en_write: "",
    lang_fr_comprehension_listen: "",
    lang_fr_comprehension_read: "",
    lang_fr_verbal_interaction: "",
    lang_fr_verbal_oral: "",
    lang_fr_write: "",
    lang_de_comprehension_listen: "",
    lang_de_comprehension_read: "",
    lang_de_verbal_interaction: "",
    lang_de_verbal_oral: "",
    lang_de_write: "",
    lang_es_comprehension_listen: "",
    lang_es_comprehension_read: "",
    lang_es_verbal_interaction: "",
    lang_es_verbal_oral: "",
    lang_es_write: "",
    lang_other_notes: ""
  }
}

export default {
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters(["downloads", "selectedLanguage"]),
    optionsLevel() {
      return [
        "",
        "A1",
        "A2",
        "B1",
        "B2",
        "C1",
        "C2"
      ]
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    async clickDownload() {
      const element = utils.findObjectDownload(this.downloads, constants.LANGUAGES, "tag");
      if (utils.checkObjectIsUndefinedOrEmpty(element)) {
        return
      }
      loader.showLoader()
      let data = "";
      await DownloadService.downloadDownload(element.id, this.selectedLanguage.locale)
          .then(resp => {
            data = resp
          })
          .catch(err => {
            console.log(err)
          })
      utils.downloadFile(data)
      loader.hideLoader()
    },
  }
}
</script>