import en from './en';
import it from './it';
import de from './de';
import fr from './fr';
import es from './es';

export default {
    en: {
        message: en
    },
    it: {
        message: it
    },
    de: {
        message: de
    },
    fr: {
        message: fr
    },
    es: {
        message: es
    }
}