<template>
  <div class="row">
        <div class="col-12 px-2">
            <panel title="Reimposta Password">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" v-model="password" class="form-control" placeholder="Password">
                        </div>
                    </div>
                    <div class="col-12">
                        <a class="btn btn-primary" @click="save">Salva</a>
                    </div>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
import LoginService from '../api/LoginService'
import notification from '../config/notification'
import utils from '../utils/utils'
import { mapGetters } from 'vuex'
import constants from '../config/constants'
export default {
    data() {
        return {
            password: ""
        }
    },
	computed: {
		...mapGetters(["selectedLanguage", "translations"]),
        registerError() {
            if(this.translations.length === 0){
                return "Errore, riprova"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.REGISTER_ERROR.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return "Errore, riprova"
            }
            return object.value[this.selectedLanguage.locale]
        },
        resetPasswordSuccess() {
            if(this.translations.length === 0){
                return "Password modificata, effettua l'accesso"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.RESET_PASSWORD_SUCCESS.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return "Password modificata, effettua l'accesso"
            }
            return object.value[this.selectedLanguage.locale]
        },
	},
    methods: {
        getError(error) {
            if(this.translations.length === 0){
                return this.registerError
            }
            let key = error.code
            if(error.attr === 'email') {
                key= `${error.attr}_${error.code}`
            }
            var object = this.translations.find(e => e.key.toUpperCase() === key.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.registerError
            }
            return object.value[this.selectedLanguage.locale]
        },
        save(){
            const params = {
                user_id: this.$route.query.user_id,
                timestamp: this.$route.query.timestamp,
                signature: this.$route.query.signature,
                password: this.password
            }
            LoginService.resetPassword(params)
                .then(() => {
                    notification.notifySuccess(this.resetPasswordSuccess)
                    this.$router.push({name: 'CustomerAreaLogin'}).catch(() => {});
                })
                .catch(err => {
                    if(utils.checkArrayIsUndefinedOrEmpty(err.response.data.errors)) {
                        notification.notifyError(this.registerError)
                        return
                    }
                    if(utils.checkObjectIsUndefinedOrEmpty(err.response.data.errors[0])) {
                        notification.notifyError(this.registerError)
                        return
                    }
                    let error = err.response.data.errors[0]
                    notification.notifyError(this.getError(error))
                })
        }
    }
}
</script>

<style>

</style>