<template>
    <panel :title="$t('message.family', 'it')">
        <div class="row">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.father', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="family_father" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.mother', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="family_mother" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.partner', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="family_partner" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.child', 'it') }}</label>
            <div class="col-md-8 col-2">
                <input v-model="family_sons" type="number" class="form-control" />
            </div>
        </div>
        <!-- <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.child') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.child') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input class="form-check-input" type="checkbox" />
            </div>
        </div> -->
    </panel>
</template>

<script>
function initialState() {
    return {
        family_father: false,
        family_mother: false,
        family_partner: false,
        family_sons: ""
    }
}

export default {
    data() {
        return initialState()
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>