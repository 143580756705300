<template>
	<div class="row mt-0" v-if="news.length > 0">
		<div class="col-md-6 col-sm-12 col-lg-6 col-xl-4 pt-0 mb-3 mb-md-0 pe-2" v-for="element in news" :key="element.id"
			:title="element.title[selectedLanguage.locale]">
			<div class="card custom-card h-100 cursor-pointer" @click="detail(element.id)">
				<img class="card-img-top" :src="utils.getPublicFile(element.cover_image)" alt="Card image cap">
				<div class="card-body d-flex flex-column">
					<p class="title-section mb-3 line-height-1">{{ element.title[selectedLanguage.locale] }}</p>
					<p v-if="utils.checkIsUndefinedOrEmpty(element.summary[selectedLanguage.locale])" class="card-text"> &nbsp; </p>
					<p v-else class="card-text">{{ element.summary[selectedLanguage.locale] }}</p>
					<a class="btn btn-primary button-md-bottom">{{ $t("message.findOutMore") }}</a>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="row">
		<p>{{ $t("message.noNews") }}</p>
	</div>
</template>

<script>
// import NewsService from '../../api/NewsService.js'
import { mapGetters } from 'vuex'
import loader from '../../config/loader.js'
import utils from '../../utils/utils.js'

export default {
	data() {
		return {
			utils,
			dataNews: []
		}
	},
	computed: {
		...mapGetters([
			"selectedLanguage",
			"news"
		])
	},
	async mounted() {
		loader.showLoader()
		// await NewsService.fetchNews()
		// 	.then(resp => {
		// 		this.dataNews = resp.data.data
		// 	})
		loader.hideLoader()
	},
	methods: {
		detail(id) {
			this.$router.push({name: "DetailNews", params: {id: id, locale: this.selectedLanguage.locale}})
		}
	}
}
</script>