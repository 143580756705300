<template>
    <div class="container px-2 curriculum">
        <p class="title-section">{{ $t("message.curriculum", 'it') }}</p>
        <p @click.stop.prevent="clickDownload" class="cursor-pointer text-justify" v-html="curriculumString"></p>
        <div class="row w-100">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 ps-0 pe-0 pe-md-2">
                <!-- <panel :title="$t('message.dateIssue')">
                    <div class="row">
                        <label class="col-md-4">{{ $t('message.dateIssue') }}</label>
                        <datepicker class="col-md-8" input-class="form-control bg-white"></datepicker>
                    </div>
                </panel> -->
                <personal-data ref="personalData"/>
                <family ref="family"/>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 pe-0 ps-0 ps-md-2">
                <panel :title="$t('message.availableHiring', 'it')">
                    <div class="row">
                        <label class="col-md-4">{{ $t('message.availableHiring', 'it') }}</label>
                        <div class="col-md-8">
                            <datepicker :language="$data[selectedLanguage.locale]" :disabled-dates="disable_dates"
                                        v-model="available_from_date" input-class="form-control bg-white"></datepicker>
                        </div>
                    </div>
                </panel>
                <sector-work ref="sectorWork"/>
                <panel :title="$t('message.healthConditions', 'it')">
                    <div class="row">
                        <label class="col-md-4 col-10 fw-normal">{{ $t('message.suitabilityAnyJob', 'it') }}</label>
                        <div class="col-md-8 col-2 checkbox-center">
                            <input v-model="health_suitability_any_job" class="form-check-input" type="checkbox"/>
                        </div>
                    </div>
                </panel>
                <panel :title="$t('message.generalNews', 'it')">
                    <div class="row">
                        <label class="col-md-4 col-10 fw-normal">{{ $t('message.ownCar', 'it') }}</label>
                        <div class="col-md-8 col-2 checkbox-center">
                            <input v-model="driver_own_vehicle" class="form-check-input" type="checkbox"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-4 form-label fw-normal">{{ $t('message.driverLicense', 'it') }}</label>
                        <div class="col-md-8">
                            <input v-model="driver_license_type" maxlength="150" type="text" class="form-control"/>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 ps-0 pe-0 pe-md-2">
                <educational-qualification ref="educationalQualification"/>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 pe-0 ps-0 ps-md-2">
                <detail-last-title ref="detailLastTitle"/>
            </div>
            <div class="col-12 px-0">
                <foreign-languages ref="foreignLanguages"/>
            </div>
            <div class="col-12 px-0">
                <knowledges ref="knowledges"/>
            </div>
            <div class="col-12 px-0">
                <experiences ref="experiences"/>
            </div>
            <div class="col-12 px-0">
                <availability-travel ref="availabilityTravel"/>
            </div>
            <div class="col-12 px-0">
                <contract ref="contract"/>
            </div>
            <div class="col-12 px-0">
                <panel :title="$t('message.notes', 'it')">
                    <div class="row">
                        <div class="col">
                            <textarea v-model="note" type="text" class="form-control" rows="10"/>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="col-12 px-0">
                <send ref="send" @send="send" @reset="reset"/>
            </div>
        </div>
    </div>
</template>

<script>
import {en, es, fr, it, de} from 'vuejs-datepicker/dist/locale'
import constants from '../config/constants'
import {mapGetters} from 'vuex'
import utils from '../utils/utils'
import swal from '../config/swal'
import CurriculumService from '../api/CurriculumService'
import loader from '../config/loader'
import notification from '../config/notification'
import DownloadService from "@/api/DownloadService";

function initialState() {
    return {
        en,
        es,
        fr,
        it,
        de,
        available_from_date: null,
        driver_own_vehicle: false,
        health_suitability_any_job: false,
        driver_license_type: "",
        note: "",
        disable_dates: {
            to: new Date()
        }
    }
}

export default {
    data() {
        return initialState()
    },
    metaInfo() {
        return {
            title: this.$t("message.curriculum"),
            titleTemplate: constants.TITLE_TEMPLATE
        }
    },
    computed: {
        ...mapGetters(['translations', 'selectedLanguage', "curriculum", "downloads"]),
        curriculumString() {
            if (this.translations.length === 0) {
                return ""
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.CURRICULUM.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if (utils.checkObjectIsUndefinedOrEmpty(object)) {
                return ""
            }
            return object.value['it']
        },
        curriculumSendSuccess() {
            if (this.translations.length === 0) {
                return "Il vostro curriculum è stato archiviato con il codice"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.CURRICULUM_SEND_SUCCESS.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if (utils.checkObjectIsUndefinedOrEmpty(object)) {
                return "Il vostro curriculum è stato archiviato con il codice"
            }
            return object.value['it'].replace(/(<([^>]+)>)/gi, "")
        },
        curriculumSendError() {
            if (this.translations.length === 0) {
                return "Errore durante l'invio"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.CURRICULUM_SEND_ERROR.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if (utils.checkObjectIsUndefinedOrEmpty(object)) {
                return "Errore durante l'invio"
            }
            return object.value['it']
        }
    },
    mounted() {
        if (utils.checkArrayIsUndefinedOrEmpty(this.curriculum)) {
            return
        }
        for (var key in this.$data) {
            if (constants.KEYS_CURRICULUM.includes(key)) {
                this.$data[key] = utils.findObject(this.curriculum, key, "key").value
            }
            for (var keyRef in this.$refs) {
                for (var keyData in this.$refs[keyRef].$data) {
                    if (constants.KEYS_CURRICULUM.includes(keyData)) {
                        this.$refs[keyRef].$data[keyData] = utils.findObject(this.curriculum, keyData, "key").value
                    }
                }
            }
        }
    },
    methods: {
        async clickDownload() {
            // var element = utils.findObject(this.downloads, constants.CONTACTS, "tag")
            const element = utils.findObjectDownload(this.downloads, constants.CURRICULUM, "tag");
            if (utils.checkObjectIsUndefinedOrEmpty(element)) {
                return
            }
            loader.showLoader()
            let data = "";
            await DownloadService.downloadDownload(element.id, 'it')
                .then(resp => {
                    data = resp
                })
                .catch(err => {
                    console.log(err)
                })
            utils.downloadFile(data)
            loader.hideLoader()
            this.clicked = true
        },
        async send() {
            this.$validator.validateAll().then(async valid => {
                if (valid) {
                    var params = utils.getCurriculumData(this.$data)
                    for (var key in this.$refs) {
                        var isValid = true
                        await this.$refs[key].$validator.validateAll().then(async validChild => {
                            isValid = validChild
                        })
                        if (!isValid) {
                            swal.errorIt(this.$t("message.fillRequiredFields", 'it'))
                            return
                        }
                        params = params.concat(utils.getCurriculumData(this.$refs[key].$data))
                    }
                    let formData = new FormData()
                    for (var keyParams in params) {
                        var element = params[keyParams]
                        if (element.key === 'note') {
                            console.log(element)

                        }
                        if (element.key === "experience") {
                            formData.append(element.key, JSON.stringify(element.value))
                            continue
                        }
                        if (element.key.includes("date")) {
                            formData.append(element.key, new Date(element.value).toISOString().split('T')[0])
                            continue
                        }
                        if (element.key.includes("file")) {
                            element.value.forEach(file => {
                                formData.append(element.key, file)
                            });
                            continue
                        }
                        formData.append(element.key, element.value)

                    }
                    loader.showLoader()
                    await CurriculumService.sendCurriculum(formData)
                        .then(resp => {
                            console.log(resp)
                            notification.notifySuccessIt(`${this.curriculumSendSuccess} ${resp.data.sequential_number}`)
                            this.reset()
                        })
                        .catch(err => {
                            console.log(err)
                            notification.notifyErrorIt(this.curriculumSendError)
                            this.$store.commit("setCurriculum", params)
                        })
                    loader.hideLoader()
                }
            });
        },
        reset() {
            Object.assign(this.$data, initialState());
            for (var key in this.$refs) {
                this.$refs[key].reset()
                this.$refs[key].$validator.reset()
            }
            for (var i in this.$refs.send.$refs) {
                var ref = this.$refs.send.$refs[i]
                ref.forEach(element => {
                    element.value = ""
                });
            }
            this.$store.commit("removeCurriculum")
            this.$validator.reset()
        }
    }
}
</script>