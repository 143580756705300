import Vue from 'vue'
import i18n from "@/i18n";

export default {
    notifyError,
    notifyWarning,
    notifySuccess,
    notifyErrorIt,
    notifyWarningIt,
    notifySuccessIt
}

function notify(type, title, text) {
    Vue.notify({
        group: 'alert',
        type: type,
        title: title,
        text: text
      })
}

function notifyError(text) {
    notify('error', i18n.t("message.errorAlert"), text)
}

function notifyWarning(text) {
    notify('warn', i18n.t("message.warningAlert"), text)
}

function notifySuccess(text) {
    notify('success', i18n.t("message.successAlert"), text)
}
function notifyErrorIt(text) {
    notify('error', i18n.t("message.errorAlert", "it"), text)
}

function notifyWarningIt(text) {
    notify('warn', i18n.t("message.warningAlert", "it"), text)
}

function notifySuccessIt(text) {
    notify('success', i18n.t("message.successAlert", "it"), text)
}