<template>
    <panel :title="$t('message.experiences', 'it')">
        <div class="row inner-row" v-for="(exp, index) in experience" :key="index">
            <div class="col-md-5">
                <div class="row mb-md-4">
                    <label class="col-md-4 form-label fw-normal">{{ $t('message.from', 'it') }}</label>
                    <div class="col-md-8">
                        <datepicker :language="$data[selectedLanguage.locale]" type="text" input-class="form-control bg-white" v-model="exp.from"/>
                    </div>
                </div>
                <div class="row mb-md-4">
                    <label class="col-md-4 form-label fw-normal">{{ $t('message.to', 'it') }}</label>
                    <div class="col-md-8">
                        <datepicker :language="$data[selectedLanguage.locale]" type="text" input-class="form-control bg-white" v-model="exp.to"/>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row mb-md-4">
                    <label class="col-md-4 form-label fw-normal">{{ $t('message.companyWorked', 'it') }}</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" v-model="exp.company"/>
                    </div>
                </div>
                <div class="row mb-3 mb-md-4">
                    <label class="col-md-4 form-label fw-normal">{{ $t('message.task', 'it') }}</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" v-model="exp.task"/>
                    </div>
                </div>
            </div>
            <div class="col-md-1 center-content mb-3">
                <b-button size="lg" class="btn-reset" variant="danger" @click="deleteExperience(index)">
                    <i class="fa fa-trash"></i>
                </b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b-button block variant="primary" class="w-100" @click="newExperience">
                    <i class="fa fa-plus"></i>
                </b-button>
            </div>
        </div>
    </panel>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../utils/utils'
import {en, es, fr, it, de} from 'vuejs-datepicker/dist/locale'

function initialState() {
    return {
        en,
        es,
        fr,
        it,
        de,
        experience: [utils.newExperience()]
    }
}

export default {
    data() {
        return initialState()
    },
    computed: {
        ...mapGetters(['selectedLanguage'])
    },
    methods: {
        deleteExperience(index) {
            this.experience.splice(index, 1)
        },
        newExperience() {
            this.experience.push(utils.newExperience())
        },
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>