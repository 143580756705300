<template>
    <div>
        <div class="row">
            <p class="title-section">{{ $t("message.news") }}</p>
        </div>
        <template-news class="padding-top-12" />
    </div>
</template>

<script>
import constants from '../config/constants'
export default {
    metaInfo() {
        return {
            title: this.$t("message.news"),
            titleTemplate: constants.TITLE_TEMPLATE
        }
    }
}
</script>