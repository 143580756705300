<template>
    <navs :title="$t('message.knowledges', 'it')" :navs="navs" :elements="knowledges" >
        <template v-slot:content="content">
            <div class="form-group row mb-4" v-for="(element, key) in knowledges[content.selectedNav]" :key="key">
                <div class="col-sm-8" v-if="key=== 'other'">
                    <input class="form-control" maxlength="50" v-model="competences_it_other_name" :placeholder="$t('message.other')"/>
                </div>
                <label v-else class="col-sm-8">{{ $t(`message.${key}`, 'it') }}</label>
                <div class="col-sm-4">
                    <select class="form-select" v-model="$data[`competences_${content.selectedNav}_${key}`]">
                        <option v-for="(level) in optionsLevel" :key="level.id" :value="level.id">
                            {{ level.value }}
                        </option>
                    </select>
                </div>
            </div>
        </template>
    </navs>
</template>

<script>
import utils from '../../utils/utils'

function initialState() {
    return {
        navs: [{
                id: "technical",
                title: "message.technicalDesign"
            },
            {
                id: "it",
                title: "message.informatics"
            },
            {
                id: "metalworkers",
                title: "message.generalMetalworkers"
            }],
            knowledges: {
                technical: utils.levelTechnicalDesign(),
                it: utils.levelInformatics(),
                metalworkers: utils.levelGeneralMetalworkers()
            },
            other: "",
            competences_it_other_name: "",
            competences_technical_drawing_read: "",
            competences_technical_drawing_draw: "",
            competences_technical_drawing_cad: "",
            competences_it_windows: "",
            competences_it_linux: "",
            competences_it_sap: "",
            competences_it_docs: "",
            competences_it_calc: "",
            competences_it_other: "",
            competences_metalworkers_forklifts: "",
            competences_metalworkers_welding: "",
            competences_metalworkers_mechanical_presses: "",
            competences_metalworkers_molds_on_press: "",
            competences_metalworkers_mold_construction: "",
            competences_metalworkers_manual_control: "",
            competences_metalworkers_cnc: "",
            competences_metalworkers_robot: ""
    }
}

export default {
    data() {
        return initialState()
    },
    computed: {
        optionsLevel() {
            return [
                {
                    id: "",
                    value: ""
                },
                {
                    id: "n",
                    value: this.$t("message.nothing", 'it')
                },
                {
                    id: "d",
                    value: this.$t("message.decent", 'it')
                },
                {
                    id: "g",
                    value: this.$t("message.great", 'it')
                }
            ]
        }
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>