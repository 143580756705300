<template>
    <panel :title="$t('message.contract', 'it')">
        <div class="row inner-row">
            <div class="col-md-6">
                <div class="row mb-3">
                    <h4 class="mb-3 ps-0">{{ $t("message.contractualExpectations", 'it') }}</h4>
                    <label class="col-md-8 col-10 fw-normal ps-0">{{ $t('message.partTime', 'it') }}</label>
                    <div class="col-md-4 col-2 checkbox-center">
                        <input v-model="contract_part_time" class="form-check-input" type="checkbox" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-md-8 col-10 fw-normal ps-0">{{ $t('message.apprenticeship', 'it') }}</label>
                    <div class="col-md-4 col-2 checkbox-center">
                        <input v-model="contract_apprentice" class="form-check-input" type="checkbox" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-md-8 col-10 fw-normal ps-0">{{ $t('message.fixedTime', 'it') }}</label>
                    <div class="col-md-4 col-2 checkbox-center">
                        <input v-model="contract_fixed_term" class="form-check-input" type="checkbox" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-md-8 col-10 fw-normal ps-0">{{ $t('message.indeterminateTime', 'it') }}</label>
                    <div class="col-md-4 col-2 checkbox-center">
                        <input v-model="contract_permanent" class="form-check-input" type="checkbox" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-md-8 col-10 fw-normal ps-0">{{ $t('message.collaboration', 'it') }}</label>
                    <div class="col-md-4 col-2 checkbox-center">
                        <input v-model="contract_collaboration" class="form-check-input" type="checkbox" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row mb-3 form-group">
                    <h4 class="mb-3">{{ $t("message.contractualEconomics", 'it') }}</h4>
                    <label class="col-md-4 col-12 fw-normal">{{ $t('message.salary', 'it') }}</label>
                    <div class="col-md-5 col-8">
                        <input v-model="contract_month_retribution" type="number" class="form-control" />
                    </div>
                    <div class="col-md-3 col-4">
                        <select v-model="contract_month_retribution_type" class="form-select" >
                            <option v-for="t in retribution_types" :key="t.id" :value="t.id">
                                {{ t.value }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mb-3 form-group">
                    <label class="col-md-4 col-12 fw-normal">{{ $t('message.monthlyPayments', 'it') }}</label>
                    <div class="col-md-5 col-12">
                        <input v-model="contract_month_payouts" type="number" class="form-control" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-md-4 col-10 ">{{ $t('message.contractualMinimum', 'it') }}</label>
                    <div class="col-md-8 col-2 checkbox-center">
                        <input v-model="contract_minimum" class="form-check-input" type="checkbox" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.lastSalary', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="contract_last_salary" type="number" class="form-control" />
            </div>
        </div>
    </panel>
</template>

<script>
function initialState() {
    return {
        contract_part_time: false,
        contract_apprentice: false,
        contract_fixed_term: false,
        contract_permanent: false,
        contract_collaboration: false,
        contract_month_retribution: "",
        contract_month_retribution_type: "",
        contract_month_payouts: "",
        contract_minimum: false,
        contract_last_salary: ""
    }
}

export default {
    data() {
        return initialState()
    },
    computed: {
        retribution_types() {
            return [
                {
                    id: "",
                    value: ""
                },
                {
                    id: "g",
                    value: this.$t("message.gross", 'it')
                },
                {
                    id: "n",
                    value: this.$t("message.net", 'it')
                }
            ]
        }
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>