import Vue from 'vue'
import Vuex from 'vuex'
import router from './config/AppRoutes'
import store from './store/store.js'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueHljs from 'vue-hljs'
import hljs from "highlight.js";
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import DateRangePicker from 'vue2-daterange-picker'
import VueI18n from 'vue-i18n'
// import messages from "./lang"
import VueEasyLightbox from 'vue-easy-lightbox'
import Vuetify from 'vuetify'
import VueJwtDecode from 'vue-jwt-decode'
import VueMeta from 'vue-meta'
import vSelect from 'vue-select'
import VeeValidate from 'vee-validate';

import LoginService from './api/LoginService.js'
import i18n from './i18n'

// components
import Carousel from './components/home/Carousel.vue'
import News from './components/home/News.vue'
import Card from './components/home/Card.vue'
import Categories from './components/home/Categories.vue'
import Langs from './components/langs/Langs.vue'
import Shape from './components/shape/Shape.vue'
import TemplateProducts from './components/products/TemplateProducts.vue'
import TemplateProductsAll from './components/products/TemplateProductsAll.vue'
import Filters from './components/products/Filters.vue'
import TemplateNews from './components/news/TemplateNews.vue'
import PersonalData from './components/curriculum/PersonalData.vue'
import Family from './components/curriculum/Family.vue'
import EducationalQualification from './components/curriculum/EducationalQualification.vue'
import SectorWork from './components/curriculum/SectorWork.vue'
import DetailLastTitle from './components/curriculum/DetailLastTitle.vue'
import ForeignLanguages from './components/curriculum/ForeignLanguages.vue'
import Knowledges from './components/curriculum/Knowledges.vue'
import Experiences from './components/curriculum/Experiences.vue'
import AvailabilityTravel from './components/curriculum/AvailabilityTravel.vue'
import Contract from './components/curriculum/Contract.vue'
import Send from './components/curriculum/Send.vue'
import Navs from './components/Navs.vue'
import ResponsiveDatatable from './components/responsive-datatable/ResponsiveDatatable.vue'
import GalleryZoom from './components/gallery-zoom/GalleryZoom.vue'
import Loading from 'vue-loading-overlay';
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import Search from './components/search/Search.vue'

// // import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'

// // import style (<= Swiper 5.x)
// import 'swiper/css/swiper.css'

// plugins css
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/style.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icons.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'vuetify/dist/vuetify.min.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-select/dist/vue-select.css';

// color admin css
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import utils from './utils/utils'

Vue.config.productionTip = false

Vue.use(VueI18n)
// const i18n = new VueI18n({
// 	locale: store.state.selectedLanguage.locale,
// 	messages, // set locale messages
// })

Vue.use(Vuex)
Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(VueHljs, { hljs })
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(VeeValidate);
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(VueEasyLightbox)
Vue.use(Vuetify)
// Vue.use(VueAwesomeSwiper)
Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('carousel', Carousel)
Vue.component('news', News)
Vue.component('card', Card)
Vue.component('langs', Langs)
Vue.component('shape', Shape)
Vue.component('template-products', TemplateProducts)
Vue.component('template-products-all', TemplateProductsAll)
Vue.component('template-news', TemplateNews)
Vue.component('filters', Filters)
Vue.component('personal-data', PersonalData)
Vue.component('family', Family)
Vue.component('educational-qualification', EducationalQualification)
Vue.component('sector-work', SectorWork)
Vue.component('detail-last-title', DetailLastTitle)
Vue.component('foreign-languages', ForeignLanguages)
Vue.component('navs', Navs)
Vue.component('knowledges', Knowledges)
Vue.component('experiences', Experiences)
Vue.component('contract', Contract)
Vue.component('send', Send)
Vue.component('availability-travel', AvailabilityTravel)
Vue.component('responsive-datatable', ResponsiveDatatable)
Vue.component('gallery-zoom', GalleryZoom)
Vue.component(VueEasyLightbox.name, VueEasyLightbox)
Vue.component("loading", Loading);
Vue.component("categories", Categories);
Vue.component('vue-select', vSelect)
Vue.component('search', Search)

// const router = new VueRouter({
// 	routes
// })

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.name === 'Logout')){
        store.dispatch("logout");
		next()
		return
	}
	// if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		// if (localStorage.getItem('token') === null || localStorage.getItem('token') === "") {
		// 	next({name: 'CustomerAreaLogin', query: {redirect: to.path}}) 
		// } else {
		if(!utils.checkIsUndefinedOrEmpty(localStorage.getItem('token'))) {
			const jwt = VueJwtDecode.decode(localStorage.getItem('token'))
			var exp = new Date(0).setUTCSeconds(jwt.exp)
			var date = new Date().getTime()
			if((exp - date) < 0){
				store.commit("setToken", "")
				store.commit("logoutUserWithoutLogin");
				// next({name: 'CustomerAreaLogin', query: {redirect: to.path}}) 
				// return
			} else {
				var params = {
					refresh: localStorage.getItem('refresh')
				}
				LoginService.refreshToken(params)
					.then(resp => {
						store.commit("setToken", resp.data.access)
					})
					.catch(err => {
						console.log(err.response)
						store.dispatch("logout");
						next()
						return
					})
			}
			next()
		}
	// } else {
		// if(to.path.includes("reset")){
		// 	var user_id = to.query.user_id
		// 	var timestamp = to.query.timestamp
		// 	var signature = to.query.signature
		// 	next({name: "ResetPassword", query: {user_id: user_id, timestamp: timestamp, signature: signature}})
		// 	return
		// }
		// if(to.path.includes("verify")){
		// 	var user_id1 = to.query.user_id
		// 	var timestamp1 = to.query.timestamp
		// 	var signature1 = to.query.signature
		// 	next({name: "VerifyRegistration", query: {user_id: user_id1, timestamp: timestamp1, signature: signature1}})
		// 	return
		// }
		next() 
	// }
})

new Vue({
  store,
  i18n,
  render: h => h(App),
  vuetify,
  router
}).$mount('#app')