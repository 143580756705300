<template>
	<!-- begin navbar-collapse -->
	<div class="collapse d-md-block w-100" v-bind:class="{ 'show': appOptions.appHeaderMegaMenuMobileToggled }">
		<div class="navbar-nav row w-100">
			<div v-for="(element, index) in dataHeader" :key="element.text" class="col p-0" :class="index === 0 || index === dataHeader.length - 1 ? 'col-1' : ''">
				<b-nav-item-dropdown v-if="element.isMultiple" class="navbar-item"
					toggle-class="navbar-link dropdown-toggle d-flex align-items-center">
					<template slot="button-content">
						<i :class="['fa fa-fw me-1', element.icon]"></i>
						<span class="d-lg-inline d-md-none">{{ element.text }}</span>
						<b class="caret ms-1"></b>
					</template>
					<b-dropdown-item href="#">Action</b-dropdown-item>
					<b-dropdown-item href="#">Another action</b-dropdown-item>
					<b-dropdown-item href="#">Something else here...</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item href="#">Separated link</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item href="#">One more separated link</b-dropdown-item>
				</b-nav-item-dropdown>
				<div class="navbar-item text-center" v-else>
					<router-link :to="{name: element.to, params: {locale: selectedLanguage.locale}}" class="navbar-link px-0 pb-1" 
						:class="[$route.path.includes(element.path) ? 'navbar-link__active' : '', getAlign(index), element.class]">
						<!-- <i :class="['fa fa-fw me-1', element.icon]"></i> -->
						<span class="d-lg-inline color-dark-gray navbar-border">{{ element.text }}</span>
						<!-- <div class="h-10 navbar-border mx-4"></div> -->
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<!-- end navbar-collapse -->
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'HeaderMegaMenu',
	data() {
		return {
			appOptions: AppOptions
		}
	},
	methods: {
		getAlign(index) {
			if(index === 0) {
				return 'align-items-start text-start'
			}
			if(index === this.dataHeader.length -1) {
				return 'align-items-end text-end'
			}
			return 'align-items-center'
		}
	},
	computed: {
		...mapGetters(["selectedLanguage"]),
		dataHeader() {
			return [{
					isMultiple: false,
					text: this.$t("message.products"),
					icon: "fa-tools",
					path: "/products",
					to: "Products",
					class: ""
				},
				{
					isMultiple: false,
					text: this.$t("message.company"),
					icon: "fa-tools",
					path: "/company",
					to: "Company",
					class: "ps-4"
				},
				{
					isMultiple: false,
					text: this.$t("message.news"),
					icon: "fa-newspaper",
					path: "/news",
					to: "News",
					class: ""
				},
				{
					isMultiple: false,
					text: "Download",
					icon: "fa-download",
					path: "/download",
					to: "Download",
					class: ""
				},
				{
					isMultiple: false,
					text: "Curriculum",
					icon: "fa-file-code",
					path: "/curriculum",
					to: "Curriculum",
					class: ""
				},
				{
					isMultiple: false,
					text: this.$t("message.contacts"),
					icon: "fa-address-book",
					path: "/contacts",
					to: "Contacts",
					class: ""
				}
			]
		}
	}
}
</script>
