<template>
	<div>
		<!-- <div class="row w-100">
            <h3>Search Results <small>3 results found</small></h3>
        </div> -->

		<!-- <shape /> -->
		<!-- END page-header -->
		<!-- BEGIN row -->
		<div class="row w-100 px-lg-5">
			<div class="col-lg-3 col-sm-12 pt-0 col-sticky">
				<filters ref="filters" :category="category" @filters="filtersFn" />
			</div>
			<!-- BEGIN col-12 -->
			<div class="col-lg-9 col-sm-12" :class="{'px-0': !isList}">

				<div v-if="data.length === 0">
					<div class="color-dark mb-0 mb-lg-4 font-28" v-html="noProducts"></div>
				</div>


				<div v-else>
					<!-- BEGIN filter-pagination -->
					<div :class="`d-block d-md-flex align-items-center mb-4 mb-md-3 ${isList ? '' : 'px-2'}`">

						<div class="btn-group">
							<a @click="isList = false" href="javascript:;" class="btn btn-white"
								:class="[!isList ? 'btn-active': '']"><i class="fa fa-th"></i></a>
							<a @click="isList = true" class="btn btn-white" :class="[isList ? 'btn-active': '']"><i
									class="fa fa-list"></i></a>
							<!-- <a href="javascript:;" class="btn btn-white"><i class="fa fa-th-large"></i></a> -->
							
						</div>
						<div class="ms-auto d-block mt-4 mt-md-0">
							<div>
								<ul class="pagination mb-0 justify-content-between">
									<li class="page-item center">{{ $t("message.perPage") }}</li>
									<li class="page-item mx-2">
										<select class="form-select w-auto" v-model="length">
											<option v-for="p in constants.PER_PAGE" :key="p" :value="p">{{ p }}</option>
										</select>
									</li>
									<li class="page-item center">{{ `${start + 1} - ${calcLength} ${$t("message.of")} ${recordsTotal}` }}</li>
									<li class="page-item ml-2" :class="start === 0 ? 'disabled' : ''"><a :disabled="start === 0" @click="changePage(false)" class="page-link"><strong>«</strong></a></li>
									<li class="page-item" :class="page === totalPages ? 'disabled' : ''"><a :disabled="page === totalPages" @click="changePage(true)" class="page-link"><strong>»</strong></a></li>
								</ul>
							</div>
							
						</div>
						<!-- END pagination -->
					</div>
					<!-- END filter-pagination -->

					<!-- BEGIN result-list -->
					<div class="result-list" :class="isList ? '' : 'row w-100'">
						<div class="result-item cursor-pointer" :class="isList ? 'custom-card col-12' : 'col-6 p-10 col-md-4 mt-0 no-bg mb-4 custom-outline-6'" @click="detail(i.id)"
							v-for="(i, index) in data" :key="`product-${index}`">
							<img :src="utils.getPublicFile(i.thumbnail)" class="result-image object-cover ratio-10-7" :class="isList ? 'w-md-auto' : 'h-md-auto w-100'">
							<div class="result-info bg-white d-flex flex-column" :class="isList ? 'list-info' : 'min-height-content'">
								<div class="btn-row mb-3" v-if="!utils.checkIsUndefinedOrEmpty(i.code)">
									<a class="no-text-decoration text-white code-product" data-toggle="tooltip" :style="getBackground(i.category.color)"
										data-container="body" data-title="Analytics">{{ i.code }}</a>
								</div>
								<p class="title color-main" v-html="i.description[selectedLanguage.locale]"></p>
								<p class="title color-dark" v-if="!utils.checkIsUndefinedOrEmpty(i.material)" v-html="i.material.title[selectedLanguage.locale]"></p>
								<p class="title color-dark" v-else><br/></p>
								<p class="location button-bottom mb-0">{{ i.category.title[selectedLanguage.locale] }}</p>
							</div>
							<div class="result-price" :class="isList ? 'w-toxl-100' : 'w-100'">
								<a class="btn btn-primary d-block w-100 mt-0">{{ $t("message.viewDetails") }}</a>
							</div>
						</div>
					</div>
					<div :class="`d-block d-md-flex align-items-center mt-4 ${isList ? '' : 'px-2'}`">
						<div class="ms-auto d-block">
							<div>
								<ul class="pagination mb-0 justify-content-between">
									<li class="page-item center">{{ $t("message.perPage") }}</li>
									<li class="page-item mx-2">
										<select class="form-select w-auto" v-model="length">
											<option v-for="p in constants.PER_PAGE" :key="p" :value="p">{{ p }}</option>
										</select>
									</li>
									<li class="page-item center">{{ `${start + 1} - ${calcLength} ${$t("message.of")} ${recordsTotal}` }}</li>
									<li class="page-item ml-2" :class="start === 0 ? 'disabled' : ''"><a :disabled="start === 0" @click="changePage(false)" class="page-link"><strong>«</strong></a></li>
									<li class="page-item" :class="page === totalPages ? 'disabled' : ''"><a :disabled="page === totalPages" @click="changePage(true)" class="page-link"><strong>»</strong></a></li>
								</ul>
							</div>
							
						</div>
						<!-- END pagination -->
					</div>
				</div>
				<div :class="`d-block d-md-flex align-items-center mt-4 ${isList ? '' : 'px-2'}`">
					<div class="ms-auto d-block color-primary">
						{{ $t("message.lastUpdate") }} {{ lastUpdate }}
					</div>
				</div>
			</div>
			<!-- END col-12 -->
		</div>
		<!-- END row -->
	</div>
</template>

<script>
import utils from '../../utils/utils.js'
import CatalogService from '../../api/CatalogService'
import {
	mapGetters
} from 'vuex'
import constants from '../../config/constants.js'
import loader from '../../config/loader.js'
import AuditService from '../../api/AuditService.js'
export default {
	data() {
		return {
			isList: false,
			data: [],
			category: "",
			utils,
			start: 0,
			length: constants.PER_PAGE_DEFAULT,
			constants,
			recordsTotal: 0,
			page: 1,
			columms: [{
					id: 0,
					field: "id"
				},
				{
					id: 1,
					field: "category"
				},
				{
					id: 2,
					field: "category__id"
				},
				{
					id: 3,
					field: "material"
				},
				{
					id: 4,
					field: "material__id"
				},
				{
					id: 5,
					field: "description"
				},
				{
					id: 6,
					field: "code"
				},
				{
					id: 7,
					field: "thumbnail"
				},
				{
					id: 8,
					field: "articlecomponent__code"
				},
				{
					id: 9,
					field: "order"
				}
			]
		}
	},
	computed: {
		...mapGetters([
			"selectedLanguage",
			"filters",
			"isLogged",
			"codeComponent",
			"configs",
			"translations"
		]),
        noProducts() {
            if(this.translations.length === 0){
                return this.$t("message.noProducts")
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.NO_PRODUCTS.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.$t("message.noProducts")
            }
            return object.value[this.selectedLanguage.locale]
        },
		totalPages() {
			return Math.ceil(this.recordsTotal / this.length)
		},
		calcLength() {
			if (this.page === 0) {
				return this.length
			}
			var size = this.length * this.page
			if (size > this.recordsTotal) {
				return this.recordsTotal
			}
			return size
		},
		lastUpdate() {
            if(this.configs.length === 0){
                return ""
            }
            var object = this.configs.find(e => e.key.toUpperCase() === constants.LAST_UPDATE.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return ""
            }
            return object.value
        },
	},
	async mounted() {
		this.category = this.$route.query.category
		if (utils.checkObjectIsUndefinedOrEmpty(this.filters) && utils.checkIsUndefinedOrEmpty(this.category)) {
			this.fetchData()
		}
	},
	watch: {
		length() {
			this.page = 1
			this.start = 0
			this.$refs.filters.filtersFn()
		}
	},
	methods: {
		getBackground(color) {
			return `background-color: ${color} !important;`
		},
		changePage(increasing) {
			if (increasing) {
				this.start += this.length
				this.page++
			} else {
				this.start -= this.length
				this.page--
			}
			this.$refs.filters.filtersFn()
		},
		async fetchData(extra = "") {
			loader.showLoader()
			var string = utils.getStringFilter(this.columms)
			// string += `&start=${this.start}&length=${this.length}`
			// if(this.start + this.length > this.recordsTotal && this.recordsTotal > 0) {
			// 	string += `&start=${this.start}&length=${this.recordsTotal - this.start}`
			// } else {
				string += `&start=${this.start}&length=${this.length}`
			// }
			
			string += extra
			await CatalogService.fetchArticlesComplete(string)
				.then(resp => {
					this.recordsTotal = resp.data.recordsFiltered
					this.data = resp.data.data
					// this.filteredData = resp.data.data
				})
				.catch(err => {
					console.log(err)
				})
			// if (this.isLogged) {
			// 	await CatalogService.fetchArticlesReserved(string)
			// 		.then(resp => {
			// 			this.recordsTotal += resp.data.recordsFiltered
			// 			this.data = this.data.concat(resp.data.data)
			// 			// this.filteredData = resp.data.data
			// 		})
			// 		.catch(err => {
			// 			console.log(err)
			// 		})
			// }
			this.toTop()
			loader.hideLoader()
		},
		detail(id) {
			var query = {}
			if (!utils.checkIsUndefinedOrEmpty(this.codeComponent)) {
				query["code"] = this.codeComponent
			}
			this.$router.push({
				name: "DetailProduct",
				params: {
					locale: this.selectedLanguage.locale,
					id: id
				},
				query: query
			})
		},
		toTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		async filtersFn(data, isFilter = false) {
			var string = ""
			data.forEach(filter => {
				switch (filter.type) {
					case "list":
						var arraySelected = []
						for (let value of filter.list) {
							if (value.selected) {
								arraySelected.push(value.id)
							}
						}
						string += `&columns[${filter.index}][search][value]=${utils.dividerArray(arraySelected)}`
						break;
					case "search":
						if (filter.id === "code") {
							this.$store.commit("setCodeComponent", filter.value)
						}
						if (filter.value == "") {
							break
						}
						string += `&columns[${filter.index}][search][value]=${filter.value}`
						break;
					case "range":
						if (utils.checkIsUndefinedOrEmpty(filter.value[0])) {
							break
						}
						if (utils.checkIsUndefinedOrEmpty(filter.value[1])) {
							break
						}
						// if(filter.unitMeasure === constants.MILLIMETERS){
						string += `&diameter_filter_from=${filter.value[0]}&diameter_filter_to=${filter.value[1]}`
						break
						// }
						// var inch = filter.value[0].includes("/")
						// console.log(filter.value[0], filter.value[1])
						// break;
					default:
						console.log("default")
				}
			});
      if(isFilter) {
        this.start = 0
      }
			this.fetchData(string)
			if(this.isLogged) {
				let json = {}
				data.forEach(element => {
					switch (element.type) {
						case 'list':
							var list = []
							element.list.forEach(el => {
								if(el.selected){
									list.push(el.id)
								}
							});
							json[element.id] = list
							break;
						case 'search':
							json[element.id] = element.value
							break;
						case 'range':
							json[element.id] = {
								unit: element.unitMeasure,
								value: element.value
							}
							break;
						default:
							break;
					}
				});
                let params = new FormData()
                params.append("data", JSON.stringify(json))
                await AuditService.newLog(constants.CATALOG_SEARCH, params)
                    .then(() => {
                        console.log(`success: ${constants.CATALOG_DOWNLOAD_ARTICLE_PDF}`)
                    })
                    .catch(() => {
                        console.log(`error: ${constants.CATALOG_DOWNLOAD_ARTICLE_PDF}`)
                    })
            }
		},
	}
}
</script>