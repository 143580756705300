<template>
    <panel :title="$t('message.sectorWork', 'it')">
        <div class="row">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.administrationControl', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_administration_control" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.commercialItaly', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_sales_italy" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.commercialAbroad', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_sales_foreign" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.warehouseShipping', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_warehouse_shipping" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.packaging', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_packaging" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.tooling', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_tooling" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.production', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_production" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 col-10 fw-normal">{{ $t('message.technicalOffice', 'it') }}</label>
            <div class="col-md-8 col-2 checkbox-center">
                <input v-model="work_technical_office" class="form-check-input" type="checkbox" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.other', 'it') }}</label>
            <div class="col-md-8 checkbox-center">
                <input v-model="work_other" maxlength="150" type="text" class="form-control" />
            </div>
        </div>
    </panel>
</template>

<script>
function initialState() {
    return {
        work_administration_control: false,
        work_sales_italy: false,
        work_sales_foreign: false,
        work_warehouse_shipping: false,
        work_packaging: false,
        work_tooling: false,
        work_production: false,
        work_technical_office: false,
        work_other: ""
    }
}

export default {
    data() {
        return initialState()
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>