<template>
    <div class="container px-2">
        <p class="title-section">{{ $t("message.company") }}</p>
        <p class="title-section mb-4 font-28">{{ $t("message.serenaManuelSpa") }}</p>
        <div>
            <img height="100%" class="card-img-top img-news object-cover custom-card" src="@/assets/images/Banner_Azienda.jpg" alt="" />
        </div>
        <p class="mt-4 text-justify">{{ this.$t("message.companyText1") }}</p>
        <p class="text-justify">{{ this.$t("message.companyText2") }}</p>
        <p class="text-justify">{{ this.$t("message.companyText3") }}</p>
        <p class="text-justify">{{ this.$t("message.companyText4") }}</p>
        <p class="text-justify">{{ this.$t("message.companyText5") }}</p>
        <p class="text-justify">{{ this.$t("message.companyText6") }}</p>
    </div>
</template>

<script>
import constants from '../config/constants'
export default {
    
    metaInfo() {
        return {
            title: this.$t("message.company"),
            titleTemplate: constants.TITLE_TEMPLATE
        }
    }
}
</script>