<template>
    <panel :title="$t('message.detailLastTitle', 'it')">
        <div class="row">
            <label class="col-md-4 fw-normal">{{ $t('message.yearGraduation', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_last_year" type="number" class="form-control" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.institute', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_last_institute" maxlength="50" type="text" class="form-control" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.city', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_last_city" maxlength="150" type="text" class="form-control" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.province', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_last_province" maxlength="150" type="text" class="form-control" />
            </div>
        </div>
        <div class="row mt-3">
            <label class="col-md-4 form-label fw-normal">{{ $t('message.finalVote', 'it') }}</label>
            <div class="col-md-8">
                <input v-model="study_last_score" maxlength="15" type="text" class="form-control" />
            </div>
        </div>
    </panel>
</template>

<script>
function initialState() {
    return {
        study_last_year: "",
        study_last_institute: "",
        study_last_city: "",
        study_last_province: "",
        study_last_score: ""
    }
}

export default {
    inject: ["parentValidator"],
    created() {
        this.$validator = this.parentValidator
    },
    data() {
        return initialState()
    },
    methods: {
        reset() {
            Object.assign(this.$data, initialState());
        }
    }
}
</script>