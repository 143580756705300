<template>
    <panel :title="title">
        <ul class="nav nav-pills nav-fill mb-3">
            <li class="nav-item" v-for="nav in navs" :key="nav.id">
                <a class="nav-link" @click="change(nav.id)" :class="{active: selectedNav === nav.id}" aria-current="page">
                    <img v-if="!utils.checkIsUndefined(nav.flag)" :src="require(`@/assets/icons/bandiera_${nav.flag}.svg`)" class="image-icon-24 me-2" alt=""> {{ $t(nav.title, 'it') }}
                </a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
            </li> -->
        </ul>
        <!-- <div class="nav-wizards-container">
            <nav class="nav nav-wizards-2 mb-3">
                <div class="nav-item col" v-for="nav in navs" :key="nav.id">
                    <a class="nav-link" :class="{
                        active: selectedNav === nav.id,
                        completed: selectedNavs.includes(nav.id)
                        }">
                        <div class="nav-text">{{ nav.title }}</div>
                    </a>
                </div>
            </nav>
        </div> -->

        <div class="card">
            <div class="card-body">
                <slot name="content" :selectedNav="selectedNav"></slot>
                <!-- <b-button v-if="selectedNav != elementsArray[0]" @click="prevLang" variant="danger">{{ $t("message.back") }}</b-button>
                <b-button v-if="selectedNav != elementsArray[elementsArray.length - 1]" @click="nextLang" class="float-end" variant="success">{{ $t("message.next") }}</b-button> -->
            </div>
        </div>
    </panel>
</template>

<script>
import utils from './../utils/utils'

export default {
    props: ["title", "navs", "elements"],
    data() {
        return {
            selectedNav: this.navs[0].id,
            // selectedNavs: [],
            utils: utils
        }
    },
    methods: {
        change(id) {
            this.selectedNav = id
        }
        // prevLang() {
        //     var index = utils.findIndexWithAttr(this.navs, "id", this.selectedNav)
        //     utils.deleteElementArray(this.selectedNavs, index)
        //     if (index > 0) {
        //         this.selectedNav = this.navs[index - 1].id
        //     }
        // },
        // nextLang() {
        //     this.selectedNavs.push(this.selectedNav)
        //     var index = utils.findIndexWithAttr(this.navs, "id", this.selectedNav)
        //     if (index != this.navs.length - 1) {
        //         this.selectedNav = this.navs[index + 1].id
        //     }
        // }
    },
    computed: {
        elementsArray() {
            return Object.keys(this.elements)
        }
    }
}
</script>