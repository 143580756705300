<template>
	<div class="row" v-if="news.length > 0">
		<p class="title-section mb-0">{{ $t("message.news") }}</p>

		<swiper class="swiper visible-pagination news" :options="swiperOption">
			<swiper-slide v-for="element in news" :key="element.id">
				<div class="swiper-zoom-container">
					<div class="card custom-card w-100 h-100 cursor-pointer text-start" @click="detail(element.id)">
						<img class="card-img-top img-news" :src="element.cover_image" alt="" />
						<div class="card-body d-flex flex-column">
							<p class="title-section mb-3 line-height-1">{{ element.title[selectedLanguage.locale] }}</p>
							<!-- <h4 class="card-title mb-10px">{{ element.title[selectedLanguage.locale] }}</h4> -->
							<p v-if="utils.checkIsUndefinedOrEmpty(element.summary[selectedLanguage.locale])"
								class="card-text"> &nbsp; </p>
							<p v-else class="card-text">{{ element.summary[selectedLanguage.locale] }}</p>
							<a @click="detail(element.id)"
								class="btn btn-sm btn-primary button-md-bottom">{{ $t("message.findOutMore") }}</a>
						</div>
					</div>
				</div>
			</swiper-slide>
			<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>

		</swiper>
		<!-- BEGIN col-3 -->
		<!-- <div class="col-xl-4 col-sm-6 pt-1" v-for="element in lastNews" :key="element.id">
				<div class="card custom-card h-100 cursor-pointer" @click="detail(element.id)">
					<img class="card-img-top img-news" :src="element.cover_image" alt="" />
					<div class="card-body d-flex flex-column">
						<p class="title-section mb-3 line-height-1">{{ element.title[selectedLanguage.locale] }}</p>

						<p v-if="utils.checkIsUndefinedOrEmpty(element.summary[selectedLanguage.locale])" class="card-text"> &nbsp; </p>
						<p v-else class="card-text">{{ element.summary[selectedLanguage.locale] }}</p>
						<a @click="detail(element.id)" class="btn btn-sm btn-primary button-md-bottom">{{ $t("message.findOutMore") }}</a>
					</div>
				</div>
			</div> -->
	</div>
</template>

<script>
// import NewsService from '../../api/NewsService.js'
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
import utils from '../../utils/utils.js'

export default {
	name: "NewsComponent",
    components: {
        Swiper,
        SwiperSlide
    },
	data() {
		return {
			utils,
			dataNews: [],
            swiperOption: {
                zoom: false,
                loop: true,
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    }
                }
            },
		}
	},
	computed: {
		...mapGetters([
			"selectedLanguage",
			"news"
		])
	},
	async mounted() {
		// await NewsService.fetchNews("&length=3")
		// 	.then(resp => {
		// 		this.dataNews = resp.data.data
		// 	})
	},
	methods: {
		detail(id) {
			this.$router.push({name: "DetailNews", params: {id: id, locale: this.selectedLanguage.locale}}).catch(() => {})
		}
	}
}
</script>