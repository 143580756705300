<template>
    <div class="row">
		<p class="title-section">{{ $t("message.forgotPassword") }}</p>
		<!-- <p class="">{{ $t("message.compile") }}</p> -->
        <div class="row padding-12">
            <panel :title="$t('message.dataForForgotPassword')">
                <div class="row w-100">
                    <div class="col-md-12">
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.email') }}</label>
                            <div class="col-md-7 col-12">
                                <input v-model="email" type="text" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="login-buttons">
                            <button @click="send" class="btn h-45px min-width-88 mr-3 btn-primary d-inline btn-lg">
                                {{ $t("message.send") }}
                            </button>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
import LoginService from '../api/LoginService';
import notification from '../config/notification';
import { mapGetters } from 'vuex';
import constants from '../config/constants';
import utils from '../utils/utils';

export default {
    data() {
        return {
            visiblePassword: false,
            email: ""
        }
    },
	computed: {
		...mapGetters(["selectedLanguage", "translations"]),
        registerError() {
            if(this.translations.length === 0){
                return "Errore, riprova"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.REGISTER_ERROR.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return "Errore, riprova"
            }
            return object.value[this.selectedLanguage.locale]
        },
        sendResetPasswordSuccess() {
            if(this.translations.length === 0){
                return this.$t("message.sendResetPasswordSuccess")
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.SEND_RESET_PASSWORD_SUCCESS.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.$t("message.sendResetPasswordSuccess")
            }
            return object.value[this.selectedLanguage.locale]
        },
	},
    methods: {
        getError(error) {
            if(this.translations.length === 0){
                return this.registerError
            }
            let key = error.code
            if(error.attr === 'email') {
                key= `${error.attr}_${error.code}`
            }
            var object = this.translations.find(e => e.key.toUpperCase() === key.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.registerError
            }
            return object.value[this.selectedLanguage.locale]
        },
        send() {
            var params = {
                login: this.email
            }
            LoginService.sendResetPassword(params)
                .then(resp => {
                    notification.notifySuccess(this.sendResetPasswordSuccess)
                    console.log(resp)
                })
                .catch(err => {
                    if(utils.checkArrayIsUndefinedOrEmpty(err.response.data.errors)) {
                        notification.notifyError(this.registerError)
                        return
                    }
                    if(utils.checkObjectIsUndefinedOrEmpty(err.response.data.errors[0])) {
                        notification.notifyError(this.registerError)
                        return
                    }
                    let error = err.response.data.errors[0]
                    notification.notifyError(this.getError(error))
                })
        }
    }
}
</script>