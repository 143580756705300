<template>
    <div class="container px-2">
		<p class="title-section">{{ $t("message.signup") }}</p>
		<p class="" v-html="$t('message.compile')"></p>
        <div class="">
            <panel :title="$t('message.dataForSignup')">
                <div class="row w-100">
                    <div class="col-md-6">
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.company') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="company" type="text" class="form-control" name="company" v-validate="'required'" />
                                <span class="error-span mt-3 d-block">{{ errors.first('company') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.country') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="country" type="text" class="form-control" name="country" v-validate="'required'" />
                                <span class="error-span mt-3 d-block">{{ errors.first('country') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.address') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="address" type="text" class="form-control" name="address" v-validate="'required'"/>
                                <span class="error-span mt-3 d-block">{{ errors.first('address') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.city') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="city" type="text" class="form-control" name="city" v-validate="'required'"/>
                                <span class="error-span mt-3 d-block">{{ errors.first('city') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.postalCode') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="postalCode" type="text" class="form-control" name="postalCode" v-validate="'required'"/>
                                <span class="error-span mt-3 d-block">{{ errors.first('postalCode') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.telephone') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="telephone" type="text" class="form-control" name="telephone" v-validate="'required'"/>
                                <span class="error-span mt-3 d-block">{{ errors.first('telephone') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.fax') }}</label>
                            <div class="col-md-7 col-12">
                                <input v-model="fax" type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.email') }} <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="email" type="text" class="form-control" name="email" v-validate="'required|email'"/>
                                <span class="error-span mt-3 d-block">{{ errors.first('email') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.repeatEmail') }}  <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="emailConfirm" type="text" class="form-control" name="repeatEmail" v-validate="'required|email'"/>
                                <span class="error-span mt-3 d-block">{{ errors.first('repeatEmail') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.password') }}  <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input  v-model="password" @keyup.enter="submit" :type="visiblePassword ? 'text' : 'password'" class=" input-eye password-field form-control d-inline" name="password" v-validate="{required: true,min:8,regex:/^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*[^a-zA-Z0-9].*[^a-zA-Z0-9]).{8,}$/}"/>
                                    <img @click="visiblePassword = !visiblePassword" :src="require(`@/assets/icons/psw_${visiblePassword ? 'nascondi' : 'vedi'}.svg`)" class="image-icon-24" alt="">
                                <span class="error-span mt-3 d-block">{{ errors.first('password') }}</span>
                            </div>
                        </div>
                        <div class="row mb-3 form-group">
                            <label class="col-md-5 col-12 fw-normal">{{ $t('message.passwordConfirm') }}  <span class="color-red">*</span></label>
                            <div class="col-md-7 col-12">
                                <input v-model="passwordConfirm" @keyup.enter="submit" :type="visiblePasswordConfirm ? 'text' : 'password'" class=" input-eye password-field form-control d-inline" name="passwordConfirm" v-validate="{required: true,min:8,regex:/^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*[^a-zA-Z0-9].*[^a-zA-Z0-9]).{8,}$/}"/>
                                    <img @click="visiblePasswordConfirm = !visiblePasswordConfirm" :src="require(`@/assets/icons/psw_${visiblePasswordConfirm ? 'nascondi' : 'vedi'}.svg`)" class="image-icon-24" alt="">
                                <span class="error-span mt-3 d-block">{{ errors.first('passwordConfirm') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row mb-3 form-group h-100">
                            <label class="col-md-2 col-12 fw-normal text-break">{{ $t('message.note') }}</label>
                            <div class="col-md-10 col-12">
                                <textarea v-model="note" rows="10" type="text" class="h-100 form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row form-group h-100">
                            <div class="login-buttons">
                                <button @click="send" class="btn h-45px min-width-88 mr-3 btn-primary d-inline btn-lg">
                                    {{ $t("message.sendRequest") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row form-group h-100">
                            <p class="color-red">{{ $t("message.requiredFields") }}</p>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
import LoginService from '../api/LoginService'
import swal from '../config/swal'
import { mapGetters } from 'vuex'
import notification from '../config/notification'
import constants from '../config/constants'
import utils from '../utils/utils'
export default {
    data() {
        return {
            visiblePassword: false,
            visiblePasswordConfirm: false,
            company: "",
            country: "",
            address: "",
            city: "",
            postalCode: "",
            telephone: "",
            fax: "",
            email: "",
            emailConfirm: "",
            password: "",
            passwordConfirm: "",
            note: ""
        }
    },
	computed: {
		...mapGetters(["downloads", "selectedLanguage", "translations"]),
        registerSuccess() {
            if(this.translations.length === 0){
                return "Richiesta di registrazione inviata. Grazie del vostro interesse. Sarete ricontattati a breve"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.REGISTER_SUCCESS.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return "Richiesta di registrazione inviata. Grazie del vostro interesse. Sarete ricontattati a breve"
            }
            return object.value[this.selectedLanguage.locale]
        },
        registerError() {
            if(this.translations.length === 0){
                return "Errore durante la registrazione, riprova"
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.REGISTER_ERROR.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return "Errore durante la registrazione, riprova"
            }
            return object.value[this.selectedLanguage.locale]
        },
        emailNotSame() {
            if(this.translations.length === 0){
                return this.$t("message.emailNotSame")
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.EMAIL_NOT_SAME.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.$t("message.emailNotSame")
            }
            return object.value[this.selectedLanguage.locale]
        },
        passwordNotSame() {
            if(this.translations.length === 0){
                return this.$t("message.passwordNotSame")
            }
            var object = this.translations.find(e => e.key.toUpperCase() === constants.PASSWORD_NOT_SAME.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.$t("message.passwordNotSame")
            }
            return object.value[this.selectedLanguage.locale]
        }
	},
    methods: {
        getError(error) {
            if(this.translations.length === 0){
                return this.registerError
            }
            let key = error.code
            if(error.attr === 'email') {
                key= `${error.attr}_${error.code}`
            }
            var object = this.translations.find(e => e.key.toUpperCase() === key.toUpperCase())
            // var object = utils.findObject(this.translations, constants.CURRICULUM, "key")
            if(utils.checkObjectIsUndefinedOrEmpty(object)){ 
                return this.registerError
            }
            return object.value[this.selectedLanguage.locale]
        },
        reset() {
            this.company = "",
            this.country = "",
            this.address = "",
            this.city = "",
            this.postalCode = "",
            this.telephone = "",
            this.fax = "",
            this.email = "",
            this.emailConfirm = "",
            this.password = "",
            this.passwordConfirm = "",
            this.note = ""
            this.$validator.reset()
        },
        send() {
            this.$validator.validateAll().then(async valid => {
                if (valid) {
                    if(this.email != this.emailConfirm) {
                        swal.error(this.emailNotSame)
                        return
                    }
                    if(this.password != this.passwordConfirm) {
                        swal.error(this.passwordNotSame)
                        return
                    }
                    var params = {
                        password: this.password,
                        username: this.email,
                        email: this.email,
                        // first_name: this.company,
                        password_confirm: this.passwordConfirm,
                        language: this.selectedLanguage.locale,
                        profile: '',
                        extra: {
                            company: this.company,
                            country: this.country,
                            address: this.address,
                            city: this.city,
                            postalCode: this.postalCode,
                            telephone: this.telephone,
                            fax: this.fax,
                            note: this.note
                        }
                    }
                    
                    LoginService.register(params)
                        .then(resp => {
                            console.log(resp)
                            swal.success(this.registerSuccess)
                            this.reset()
                        })
                        .catch(err => {
                            if(utils.checkArrayIsUndefinedOrEmpty(err.response.data.errors)) {
                                notification.notifyError(this.registerError)
                                return
                            }
                            if(utils.checkObjectIsUndefinedOrEmpty(err.response.data.errors[0])) {
                                notification.notifyError(this.registerError)
                                return
                            }
                            let error = err.response.data.errors[0]
                            notification.notifyError(this.getError(error))
                        })
                }
            })
            
        }
    }
}
</script>