import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchDownloads(extra = "") {
        return Api().get('api/download/?format=datatables' + extra)
    },
    fetchDownloadsFooter(extra = "") {
        return Api().get('api/download_footer/?format=datatables' + extra)
    },
    fetchDownloadsReserved(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/download_reserved/?format=datatables' + extra)
    },
    fetchPriceList(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/price_list/?format=datatables' + extra)
    },
    getDownload(id) {
        return Api().get(`api/download/${id}/`)
    },
    downloadDownload(id, lang) {
        return Api().get(`api/download/download/${id}/${lang}/`, {
            responseType: 'blob'
          })
    },
    downloadReservedDownload(id, lang) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/download_reserved/download/${id}/${lang}/`, {
            responseType: 'blob'
          })
    },
    downloadPriceList(id, lang) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/price_list/download/${id}/${lang}/`, {
            responseType: 'blob'
          })
    },
    getReservedCatalog(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post('api/catalog/reserved/', params, {
            responseType: 'blob'
          })
    },
}
