<template>
    <div>
        <div class="row w-100 px-lg-5">
            <p class="title-section">{{ $t("message.products") }}</p>
        </div>
        <template-products-all/>
        
    </div>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: this.$t("message.products"),
            titleTemplate: 'Serena Manuel | %s'
        }
    }
}
</script>