<template>
  <div class="row justify-content-center">
    <div class="col-10 col-md-11 pb-0">
      <p class="title-section fs-33 mb-0 color-dark-gray">{{ $t("message.productDetail") }}</p>
    </div>
    <div class="col-2 col-md-1 text-end pb-0">
      <img @click="back()" width="100%" src="@/assets/icons/freccia_indietro.svg"
           class=" cursor-pointer image-icon-height-36" alt="back">
      <!-- <b-button @click="back" class="px-0 btn btn-icon btn-primary" variant="primary w-100"><img src="@/assets/icons/freccia_indietro_bianca.svg" class="image-icon-height-36" alt=""></b-button> -->
    </div>
    <div class="col-12">
      <hr class="w-100 mt-0"/>
    </div>
    <div class="col-10 col-md-11 py-0 my-3 min-height-150 d-flex flex-column">
      <div class="min-h-150 d-flex-column">
        <p class="title-section mb-4 line-height-1 font-size-40" v-html="getDescription"></p>
        <p class="font-size-35 line-height-1 color-dark mb-0 button-md-bottom lh-80"
           v-if="!utils.checkIsUndefinedOrEmpty(material)" v-html="material.title[selectedLanguage.locale]"></p>
      </div>

    </div>
    <div class="col-2 col-md-1 text-end ps-0">
      <a :style="backgroundColor" class="btn no-cursor min-w-66 btn-primary font-size-25 code-product">{{ code }}</a>
    </div>
    <div class="row my-5 px-0">
      <div class="w-auto">
        <a @click="downloadPdf" class=" btn w-155 btn-danger btn-article d-block">{{ $t("message.article") }}
          <img height="100%" src="@/assets/icons/articolo_pdf.svg" class="image-icon-24" alt="">
        </a>
      </div>
    </div>
    <div class="row mb-3 mb-md-5">
      <div class="col-12 col-md-6 p-0 mb-3 mb-md-0" v-if="technicals.length > 0">
        <gallery-zoom :article_id="id" :images="technicals" :orientation="orientation" :isLeft="true"/>
      </div>
      <div class="col-12 col-md-6 p-0 mb-3 mb-md-0" v-if="photos.length > 0">
        <gallery-zoom :article_id="id" :images="photos" :orientation="orientation" :isLeft="false"/>
      </div>
    </div>
    <responsive-datatable v-if="!utils.checkArrayIsUndefinedOrEmpty(data)" :article_id="id" :columns="columns"
                          :data="data" :header="header"/>
    <div v-if="onRequest" class="row text-uppercase w-100 mt-5" :class="[utils.checkArrayIsUndefinedOrEmpty(data) ? 'mt-0' : 'mt-5']">
      <p class="bg-gray px-4 py-3 text-available">{{ availableString }}</p>
    </div>
    <div class="row w-100" :class="getMarginNotes(data)">
      <p v-for="note in translatedNotes" :key="note"
         class="bg-main no-border custom-card-dark px-4 py-2 text-note">{{ note }}</p>
    </div>
  </div>
</template>

<script>
import GalleryZoom from '../components/gallery-zoom/GalleryZoom.vue'
import CatalogService from '../api/CatalogService.js'
import AuditService from '../api/AuditService.js'
import utils from '../utils/utils.js'
import {
  mapGetters
} from 'vuex'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import notification from "@/config/notification";
// import swal from '../config/swal.js'


export default {
  components: {GalleryZoom},
  metaInfo() {
    return {
      title: this.description[this.selectedLanguage.locale],
      titleTemplate: constants.TITLE_TEMPLATE
    }
  },
  data() {
    return {
      utils,
      header: [],
      id: "",
      columns: [],
      data: [],
      category: null,
      photos: [],
      code: "",
      technicals: [],
      description: "",
      material: null,
      notes: {},
      orientation: "V",
      fromRoute: null
    }
  },
  async mounted() {
    loader.showLoader()
    this.id = this.$route.params.id
    if (this.isLogged) {
      let data = new FormData()
      data.append("data", utils.stringLogOnlyArticle(this.id))
      await AuditService.newLog(constants.CATALOG_VIEW_ARTICLE, data)
          .then(() => {
            console.log(`success: ${constants.CATALOG_VIEW_ARTICLE}`)
          })
          .catch(() => {
            console.log(`error: ${constants.CATALOG_VIEW_ARTICLE}`)
          })
    }
    await CatalogService.getArticleComponent(this.id)
        .then(resp => {
          this.category = resp.data.category
          this.description = resp.data.description
          this.notes = resp.data.notes
          this.header = resp.data.characteristic
          this.data = resp.data.components
          this.material = resp.data.material
          this.code = resp.data.code
          this.orientation = resp.data.gallery_orientation
          resp.data.images.forEach(image => {
            switch (image.type) {
              case constants.IMAGE_PHOTO:
                this.photos.push(image)
                break;
              case constants.IMAGE_TECHNICAL:
                this.technicals.push(image)
                break;
              default:
                break;
            }
          });
        })
        .catch(err => {
          console.log(err)
        })
    loader.hideLoader()
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "isLogged",
      "translations"
    ]),
    getDescription() {
      if (utils.checkIsUndefinedOrEmpty(this.description[this.selectedLanguage.locale])) {
        return this.description['it']
      }
      return this.description[this.selectedLanguage.locale]
    },
    translatedNotes() {
      if (utils.checkObjectIsUndefinedOrEmpty(this.notes)) {
        return []
      }
      if (utils.checkIsUndefinedOrEmpty(this.notes[this.selectedLanguage.locale])) {
        return []
      }
      return this.notes[this.selectedLanguage.locale].split("#")
    },
    availableString() {
      if (this.translations.length === 0) {
        return ""
      }
      var object = this.translations.find(e => e.key.toUpperCase() === constants.AVAILABLE_ON_REQUEST.toUpperCase())
      if (utils.checkObjectIsUndefinedOrEmpty(object)) {
        return ""
      }
      return object.value[this.selectedLanguage.locale]
    },
    onRequest() {
      for (var i in this.data) {
        if (this.data[i].on_request) {
          return true
        }
      }
      return false
    },
    borderLeftColor() {
      if (utils.checkIsUndefinedOrEmpty(this.category)) {
        return ""
      }
      if (utils.checkIsUndefinedOrEmpty(this.category.color)) {
        return ""
      }
      return `border-left: 5px solid ${this.category.color} !important;`
    },
    backgroundColor() {
      if (utils.checkIsUndefinedOrEmpty(this.category)) {
        return ""
      }
      if (utils.checkIsUndefinedOrEmpty(this.category.color)) {
        return ""
      }
      return `background-color: ${this.category.color} !important; border-color: ${this.category.color} !important;`
    }
  },
  methods: {
    getMarginNotes(data) {
      if(!utils.checkArrayIsUndefinedOrEmpty(data) && !this.onRequest) {
        return 'mt-5'
      }
      return 'mt-0'
    },
    async downloadPdf() {
      // if(!this.isLogged) {
      //     swal.error("Devi essere loggato")
      //     return
      // }
      loader.showLoader()
      // window.open(`${utils.baseURL()}/api/catalog/download/article/${this.id}/${this.selectedLanguage.locale}/`, "_blank")
      await CatalogService.downloadArticle(this.id, this.selectedLanguage.locale)
          .then(resp => {
            utils.downloadFile(resp)
          })
          .catch(err => {
            notification.notifyError("Errore durante il download")
            console.log(err)
          })
      if (this.isLogged) {
        let data = new FormData()
        data.append("data", utils.stringLogOnlyArticle(this.id))
        await AuditService.newLog(constants.CATALOG_DOWNLOAD_ARTICLE_PDF, data)
            .then(() => {
              console.log(`success: ${constants.CATALOG_DOWNLOAD_ARTICLE_PDF}`)
            })
            .catch(() => {
              console.log(`error: ${constants.CATALOG_DOWNLOAD_ARTICLE_PDF}`)
            })
      }
      loader.hideLoader()
    },
    back() {
      if(utils.checkIsUndefinedOrEmpty(this.fromRoute.name)) {
        this.$router.push({name: "Products", params: {locale: this.selectedLanguage.locale}})
        return
      }
      this.$router.go(-1)
      // this.$router.push({name: "Products", params: {locale: this.selectedLanguage.locale}})
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromRoute = from;
    })
  },
}
</script>