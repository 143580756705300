import Api from './index.js'
// import store from '../store/store.js'

export default {
    fetchNews(extra = "") {
        return Api().get('api/news/?format=datatables' + extra)
    },
    getNews(id) {
        return Api().get(`api/news/${id}/`)
    }
}
