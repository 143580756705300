<template>
    <div class="row">
        <!-- <div class="col"> -->
        <swiper class="swiper visible-pagination" :options="swiperOption">
            <swiper-slide v-for="(image, index) in gallery" :key="index">
                <div class="swiper-zoom-container">
                    <img :src="image" alt="image" class="w-100">
                    <!-- <div class="px-1 custom-card pb-0 h-100 w-100 cursor-pointer" @click="setCategory(category)">
                        <div class="h-100">
                            <img class="h-50 h-md-70 card-img-top img-news object-cover" :src="category.cover_image" alt="" />
                            <p class="h-40 h-md-20 gray-text text-center mb-0 px-3 line-height-15">{{ category.title[selectedLanguage.locale] }}</p>
                            <div class="mx-4 mx-md-5 h-10" :style="getBorder(category.color)">
                            </div>
                        </div>
                    </div> -->
                </div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

        </swiper>
            <!-- <b-carousel id="carousel-home"  style="text-shadow: 0px 0px 2px #000" fade indicators
                controls img-width="1024" img-height="480"
      background="#ababab">
                <b-carousel-slide v-for="(image, index) in gallery" :key="index"
                    :img-src="image"></b-carousel-slide>
            </b-carousel> -->
        <!-- </div> -->
    </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    name: "Carousel",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOption: {
                zoom: false,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 20,
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
        }
    },
    computed: {
        gallery() {
            return [
                require("@/assets/products/01.jpg"),
                require("@/assets/products/02.jpg"),
                require("@/assets/products/03.jpg"),
                require("@/assets/products/04.jpg"),
                require("@/assets/products/05.jpg"),
                require("@/assets/products/06.jpg")
            ]
        }
    }
}
</script>